/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import attributesToProps from 'html-react-parser/lib/attributes-to-props';
import domToReact from 'html-react-parser/lib/dom-to-react';

import BannerModal from 'Component/BannerModal';
import CountdownTimer from 'Component/CountdownTimer';
import {
    Html as SourceHtml
} from 'SourceComponent/Html/Html.component';

import './Html.override.style';

/** @namespace Pwa/Component/Html/Component/HtmlComponent */
export class HtmlComponent extends SourceHtml {
    rules = [
        ...this.rules,
        {
            query: { attribs: ['mc-modal-banner', 'mc-modal-id', 'mc-modal-type'] },
            replace: this.replaceModalBanner
        },
        {
            query: {
                attribs: [
                    'mc-countdown-date',
                    'mc-countdown-type',
                    'mc-countdown-style',
                    'mc-countdown-units',
                    'mc-countdown-days'
                ]
            },
            replace: this.replaceCountDown
        }
    ];

    replaceModalBanner(elem) {
        const { children, attribs } = elem;

        const {
            'mc-modal-banner': banner,
            'mc-modal-id': id,
            'mc-modal-type': type,
            ...attrs
        } = attribs;

        return (
            <BannerModal
              { ...attributesToProps({
                  ...attrs, id, banner, type
              }) }
            >
                { domToReact(children, this.parserOptions) }
            </BannerModal>
        );
    }

    replaceCountDown(elem) {
        const { attribs } = elem;
        const {
            'mc-countdown-date': date,
            'mc-countdown-type': type,
            'mc-countdown-style': mods,
            'mc-countdown-units': isFull,
            'mc-countdown-days': isWithoutDays,
            ...attrs
        } = attribs;

        return (
            <CountdownTimer
              { ...attributesToProps({
                  ...attrs, date, type, mods, isFull, isWithoutDays
              }) }
            />
        );
    }
}

export default HtmlComponent;
