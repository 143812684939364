// TODO update this import when renamed
import { ACTIVATE_PROMO_TOPNAV, DEACTIVATE_PROMO_TOPNAV } from './Promo.action';

/** @namespace Pwa/Store/Promo/Reducer/getInitialState */
export const getInitialState = () => ({
    topNavActive: false
});

/** @namespace Pwa/Store/Promo/Reducer/PromoReducer */
export const PromoReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case ACTIVATE_PROMO_TOPNAV:
        return { ...state, topNavActive: true };
    case DEACTIVATE_PROMO_TOPNAV:
        return { ...state, topNavActive: false };

    default:
        return state;
    }
};

export default PromoReducer;
