import {
    connectStateResults
} from 'react-instantsearch-dom';

import './AlgoliaInstantSearch.style';

export const AlgoliaResults = connectStateResults(
    ({ searchResults, children }) => (searchResults && searchResults.nbHits !== 0 ? (
        children
    ) : (
        <div block="AlgoliaResults" elem="notFound">{ __('No results were found') }</div>
    ))
);

export default AlgoliaResults;
