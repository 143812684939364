export const BLACK_FRIDAY_WARMUP = 'BLACK_FRIDAY_WARMUP';
export const BLACK_FRIDAY = 'BLACK_FRIDAY';
export const CYBER_MONDAY = 'CYBER_MONDAY';
export const BLACK_FRIDAY_2022 = 'BLACK_FRIDAY_2022';
export const STANDARD = 'STANDARD';

export function getPhase() {
    const phases = [
        [BLACK_FRIDAY_WARMUP, new Date('2021/11/25 00:00:00'), new Date('2021/11/25 23:59:59')],
        [BLACK_FRIDAY, new Date('2021/11/26 00:00:00'), new Date('2021/11/26 23:59:59')],
        [CYBER_MONDAY, new Date('2021/11/29 00:00:00'), new Date('2021/11/29 23:59:59')],
        [BLACK_FRIDAY_2022, new Date('2022/10/21 00:00:00'), new Date('2022/01/31 23:59:59')]
    ];
    const now = new Date();

    // eslint-disable-next-line fp/no-let
    for (let i = 0; i < phases.length; i++) {
        const phase = phases[i];
        if (phase[1].getTime() < now.getTime() && phase[2].getTime() > now.getTime()) {
            return phase[0];
        }
    }

    return STANDARD;
}
