import { LOAD_MC_AVAILABLE_VARIANTS_ACTION_TYPE } from './McAvailableVariants.action';

/** @namespace Pwa/Store/McAvailableVariants/Reducer/getInitialState */
export const getInitialState = () => ({
    mcAvailableVariants: []
});

/** @namespace Pwa/Store/McAvailableVariants/Reducer/McAvailableVariantsReducer */
export const McAvailableVariantsReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case LOAD_MC_AVAILABLE_VARIANTS_ACTION_TYPE:
        const { payload } = action;

        return {
            ...state,
            mcAvailableVariants: payload
        };

    default:
        return state;
    }
};

export default McAvailableVariantsReducer;
