// TODO rename

export const BLACK_FRIDAY_COUNTER_PRODUCT_ACTION_TYPE = 'BLACK_FRIDAY_COUNTER_PRODUCT_ACTION_TYPE';

// TODO rename
/** @namespace Pwa/Store/BlackFridayCounterProduct/Action/loadBlackFridayCounterProduct */
export const loadBlackFridayCounterProduct = (payload) => ({
    type: BLACK_FRIDAY_COUNTER_PRODUCT_ACTION_TYPE,
    payload
});
