import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    NewVersionPopupContainer as SourceNewVersionPopupContainer
} from 'SourceComponent/NewVersionPopup/NewVersionPopup.container';

/** @namespace Pwa/Component/NewVersionPopup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/NewVersionPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Component/NewVersionPopup/Container/NewVersionPopupContainer */
export class NewVersionPopupContainer extends SourceNewVersionPopupContainer {
    state= {
        registration: null
    };

    invokeServiceWorkerUpdateFlow() {
        // const { showPopup, goToPreviousHeaderState, device } = this.props;
        //
        // showPopup({
        //     title: __('New version available!')
        // });
        //
        // if (device.isMobile) {
        //     goToPreviousHeaderState();
        // }
    }

    toggleNewVersion() {
        const { hideActiveOverlay } = this.props;
        const { registration } = this.state;

        if ('serviceWorker' in navigator) {
            if (registration.waiting) {
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            }
        }

        hideActiveOverlay();
        window.location.reload();
    }

    componentDidMount() {
        // const { showPopup, goToPreviousHeaderState, device } = this.props;

        if ('serviceWorker' in navigator) {
            // (async () => {
            //     const registration = await navigator.serviceWorker.register('/service-worker.js');
            //     this.setState({
            //         registration
            //     });

            //     // ensure the case when the updatefound event was missed is also handled
            //     // by re-invoking the prompt when there's a waiting Service Worker
            //     if (registration.waiting) {
            //         this.invokeServiceWorkerUpdateFlow(registration);
            //     }

            //     // detect Service Worker update available and wait for it to become installed
            //     registration.addEventListener('updatefound', () => {
            //         if (registration.installing) {
            //         // wait until the new Service worker is actually installed (ready to take over)
            //             registration.installing.addEventListener('statechange', () => {
            //                 if (registration.waiting) {
            //                 // if there's an existing controller (previous Service Worker), show the prompt
            //                     if (navigator.serviceWorker.controller) {
            //                         this.invokeServiceWorkerUpdateFlow(registration);
            //                     } else {
            //                         // eslint-disable-next-line no-console
            //                         console.log('Service Worker initialized.');
            //                     }
            //                 }
            //             });
            //         }
            //     });

            // // eslint-disable-next-line fp/no-let
            // // const refreshing = false;

            // // detect controller change and refresh the page
            // // navigator.serviceWorker.addEventListener('controllerchange', (e) => {
            // //     if (!refreshing) {
            // //         window.location.reload();
            // //         refreshing = true;
            // //     }
            // // });
            // })();
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVersionPopupContainer);
