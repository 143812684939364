import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    McMenuScrollFreezeContainer
} from 'Component/McMenuScrollFreeze/McMenuScrollFreeze.container';
import {
    changeCurrentMcMenuItem,
    resetCurrentMcMenuItem
} from 'Store/McMenu/McMenu.action';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { McMenuNodeType } from 'Type/McMenuType';
import { toggleScroll } from 'Util/Browser';

import McMenuItem from './McMenuItem.component';

/** @namespace Pwa/Component/McMenuItem/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    currentMenuItem: state.McMenuReducer.currentItem
});

/** @namespace Pwa/Component/McMenuItem/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    changeCurrentItem: (path) => dispatch(changeCurrentMcMenuItem(path)),
    resetCurrentItem: () => dispatch(resetCurrentMcMenuItem()),
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Pwa/Component/McMenuItem/Container/McMenuItemContainer */
export class McMenuItemContainer extends McMenuScrollFreezeContainer {
    static propTypes = {
        data: McMenuNodeType.isRequired,
        changeCurrentItem: PropTypes.func.isRequired,
        resetCurrentItem: PropTypes.func.isRequired,
        currentMenuItem: PropTypes.string,
        showOverlay: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    static defaultProps = {
        currentMenuItem: null
    };

    containerFunctions = {
        onMenuOpen: this.onMenuOpen.bind(this),
        onMenuClose: this.onMenuClose.bind(this)
    };

    closeMenuWrapper() {
        const { resetCurrentItem, hideActiveOverlay, currentScroll: { active: scrollStatus } } = this.props;

        resetCurrentItem();
        hideActiveOverlay();
        if (scrollStatus) {
            this.unfreezeScroll();
        }
    }

    openMenuWrapper() {
        const { data: { path }, changeCurrentItem, showOverlay } = this.props;

        changeCurrentItem(path);
        showOverlay(path);
    }

    checkCurrentMenu() {
        const { data: { path }, currentMenuItem } = this.props;

        return (path === currentMenuItem);
    }

    containerProps = () => {
        const { data } = this.props;

        return {
            data,
            isOpen: this.checkCurrentMenu()
        };
    };

    onMenuClose() {
        if (this.checkCurrentMenu()) {
            this.closeMenuWrapper();
        }
    }

    onMenuOpen() {
        if (this.checkCurrentMenu()) {
            this.closeMenuWrapper();
        } else {
            this.openMenuWrapper();
        }
    }

    freezeScroll() {
        const { currentScroll: { scroll: currentScrollAmount }, setScrollPage } = this.props;
        const scrollAmount = currentScrollAmount || window.pageYOffset || document.body.scrollTop;
        setScrollPage(scrollAmount);
        toggleScroll(false);
        document.body.style.marginTop = `${-scrollAmount}px`;
    }

    unfreezeScroll() {
        const { currentScroll: { scroll: currentScrollAmount }, resetScrollPage } = this.props;
        toggleScroll(true);
        document.body.style.marginTop = 0;
        window.scrollTo(0, currentScrollAmount);
        resetScrollPage();
    }

    render() {
        return (
            <McMenuItem
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(McMenuItemContainer);
