import { BLACK_FRIDAY_COUNTER_CAROUSEL_LOAD_PRODUCTS } from './BlackFridayCounterCarousel.action';

/** @namespace Pwa/Store/BlackFridayCounterCarousel/Reducer/getInitialState */
export const getInitialState = () => ({
    sliderProducts: []
});

/** @namespace Pwa/Store/BlackFridayCounterCarousel/Reducer/BlackFridayCounterCarouselReducer */
export const BlackFridayCounterCarouselReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case BLACK_FRIDAY_COUNTER_CAROUSEL_LOAD_PRODUCTS:
        const { sliderProducts } = action;

        return {
            ...state,
            sliderProducts
        };

    default:
        return state;
    }
};

export default BlackFridayCounterCarouselReducer;
