/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';
import Overlay from 'Component/Overlay';
import { McMenuNodeType } from 'Type/McMenuType';

import './McMenuDropdown.style';

/** @namespace Pwa/Component/McMenuDropdown/Component/McMenuDropdownComponent */
export class McMenuDropdownComponent extends PureComponent {
    static propTypes = {
        data: McMenuNodeType.isRequired,
        onDropdownMenu: PropTypes.func.isRequired,
        onHideOverlay: PropTypes.func.isRequired,
        onVisibleOverlay: PropTypes.func.isRequired,
        current_submenu: PropTypes.string.isRequired,
        rewriteUrls: PropTypes.func.isRequired
    };

    renderMenuDropDownItem(item) {
        const { nodes } = item;
        if (nodes.length > 0) {
            return this.renderDropDownSubMenu(item);
        }

        return this.renderDropDonwLink(item);
    }

    renderDropDonwLink(item, customName = '') {
        const {
            name, url
        } = item;

        const { rewriteUrls } = this.props;

        const displayName = (customName) || name;

        const custom_url = rewriteUrls(url);

        return (
            <li
              block="McMenuDropdown"
              elem="Item"
            >
                <Link
                  block="McMenuDropdown"
                  elem="Text"
                  to={ custom_url }
                >
                    { displayName }
                </Link>
            </li>
        );
    }

    renderDropDownSubMenu(item) {
        const {
            nodes, name, path
        } = item;
        const { onDropdownMenu, current_submenu } = this.props;

        const caret = (path === current_submenu)
            ? <i className="fa fa-caret-left" aria-hidden="true" />
            : <i className="fa fa-caret-right" aria-hidden="true" />;
        const isActive = (path === current_submenu);

        return (
            <li
              block="McMenuDropdown"
              elem="Item"
            >
                <span
                  key={ path }
                  role="button"
                  tabIndex="0"
                  block="McMenuDropdown"
                  elem="Text"
                  mods={ { isActive } }
                  onClick={ () => onDropdownMenu(path) }
                >
                    { name }
                </span>
                { caret }
                { (path === current_submenu) && (
                    <ul
                      block="McMenuDropdown"
                      elem="Menu"
                      mods={ { isSubDropdown: true } }
                    >
                        { this.renderDropDonwLink(item, __('All 2')) }
                        { nodes.map((subitem) => this.renderDropDonwLink(subitem)) }
                    </ul>
                ) }
            </li>
        );
    }

    render() {
        const { onHideOverlay, onVisibleOverlay, data: { path, nodes } } = this.props;
        return (
              <Overlay
                id={ path }
                mix={ { block: 'McMenuDropdown', elem: 'Menu' } }
                onHide={ () => onHideOverlay() }
                onVisible={ () => onVisibleOverlay() }
              >
                <ul>
                    { nodes.map((item) => this.renderMenuDropDownItem(item)) }
                </ul>
              </Overlay>
        );
    }
}

export default McMenuDropdownComponent;
