export const LOAD_WAREHOUSE = 'LOAD_WAREHOUSE';
export const LOADING = 'LOADING';

/** @namespace Pwa/Store/Warehouse/Action/loadWarehouse */
export const loadWarehouse = (payload) => ({
    type: LOAD_WAREHOUSE,
    payload
});

/** @namespace Pwa/Store/Warehouse/Action/changeLoader */
export const changeLoader = (isLoading) => ({
    type: LOADING,
    isLoading
});
