/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import Popup from '@scandipwa/scandipwa/src/component/Popup/Popup.component';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { POPUP } from 'Component/Header/Header.config';
// import {
//     MENU
// } from 'Component/Header/Header.config';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    PopupContainer as SourcePopupContainer
} from 'SourceComponent/Popup/Popup.container';

export {
    mapDispatchToProps
};

/** @namespace Pwa/Component/Popup/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    navigationStateName: state.NavigationReducer.TOP_NAVIGATION_TYPE.navigationState.name,
    activeOverlay: state.OverlayReducer.activeOverlay
});

/** @namespace Pwa/Component/Popup/Container/PopupContainer */
export class PopupContainer extends SourcePopupContainer {
    static propTypes = {
        ...SourcePopupContainer.propTypes,
        isSidePopup: PropTypes.bool,
        isFullPopup: PropTypes.bool,
        navigationStateName: PropTypes.string.isRequired,
        activeOverlay: PropTypes.string.isRequired,
        popupRef: PropTypes.any, // eslint-disable-line react/forbid-prop-types
        customClass: PropTypes.string
    };

    static defaultProps = {
        ...SourcePopupContainer.defaultProps,
        isSidePopup: false,
        isFullPopup: false,
        popupRef: React.createRef()
    };

    componentWillUnmount() {
        const { popupRef, navigationStateName } = this.props;

        if (navigationStateName !== POPUP) {
            return;
        }

        popupRef?.current?.hidePopUp();
        popupRef?.current?.unfreezeScroll();
    }

    // componentDidUpdate(_) {
    //     const {
    //         navigationStateName
    //     } = this.props;

    //     if (navigationStateName === MENU) {

    //     }
    // }

    render() {
        const { popupRef } = this.props;

        return (
            <Popup
              { ...this.props }
              { ...this.containerProps() }
              { ...this.containerFunctions }
              ref={ popupRef }
              isPopup
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContainer);
