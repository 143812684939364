/* eslint-disable react/forbid-prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import PropTypes from 'prop-types';

import CmsBlock from 'Component/CmsBlock';
import CmsPopup from 'Component/CmsPopup';
import ContentWrapper from 'Component/ContentWrapper';
import { CART, MENU, MENU_SUBCATEGORY } from 'Component/Header/Header.config';
import Image from 'Component/Image';
// import LegalNotesPopup from 'Component/LegalNotesPopup';
import { LEGAL_NOTES_POPUP } from 'Component/LegalNotesPopup/LegalNotesPopup.config';
import NewsletterCustom from 'Component/NewsletterCustom';
import ScrollToTop from 'Component/ScrollToTop';
import TempiDiConsegnaBanner from 'Component/TempiDiConsegnaBanner';
import {
    Footer as SourceFooter
} from 'SourceComponent/Footer/Footer.component';

import './Footer.override.style';

export const HIDE_FOOTER_STATES_DESKTOP = [
];

export const HIDE_FOOTER_STATES_MOBILE = [
    MENU,
    CART,
    MENU_SUBCATEGORY
];

/** @namespace Pwa/Component/Footer/Component/FooterComponent */
export class FooterComponent extends SourceFooter {
    static propTypes ={
        ...SourceFooter.propTypes,
        navigationState: PropTypes.object.isRequired
    };

    state = {
        isVisible: true
    };

    shouldIncludeFooter(navigationStateName) {
        const { device } = this.props;
        const statesConfig = device.isMobile ? HIDE_FOOTER_STATES_MOBILE : HIDE_FOOTER_STATES_DESKTOP;

        if (!statesConfig.includes(navigationStateName)) {
            this.showFooter();
        } else {
            this.hideFooter();
        }
    }

    componentDidUpdate(prevProps) {
        const { navigationState: { name: prevName } } = prevProps;
        const { navigationState: { name } } = this.props;

        if (prevName !== name) {
            this.shouldIncludeFooter(name);
        }
    }

    componentDidMount() {
        const { navigationState: { name } } = this.props;
        this.shouldIncludeFooter(name);
    }

    showFooter = () => this.setState({ isVisible: true });

    hideFooter = () => this.setState({ isVisible: false });

    renderCmsBlockWrapper() {
        const { footer_content: { footer_cms } = {} } = window.contentConfiguration;

        return (
            <div
              block="Footer"
              elem="CmsBlockWrapper"
              mix={ { block: 'Footer', elem: 'Content' } }
            >
                <div
                  block="Footer"
                  elem="Columns"
                  mix={ { block: 'ContentWrapper' } }
                >
                    <CmsBlock identifier={ footer_cms } />
                </div>
            </div>
        );
    }

    renderAltro() {
        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'AltroContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'AltroContent' } }
              label=""
            >
                <CmsBlock identifier="footer-altro" />
            </ContentWrapper>
        );
    }

    renderCopyrightContent() {
        const { copyright } = this.props;
        const imgSrc = '/media/wysiwyg/loghi/logo-light.png';
        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'CopyrightContent' } }
              label=""
            >
                <Image
                  src={ imgSrc }
                  mix={ {
                      block: 'Footer',
                      elem: 'Logo'
                  } }
                />
                <span block="Footer" elem="Copyright">
                    ©MondoConvenienza
                        <br />
                        { copyright }
                        |&nbsp;
                        { this.renderShareWishlistButton() }
                </span>

            </ContentWrapper>
        );
    }

    renderShareWishlistButton() {
        const {
            showPopup
        } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <span
              block="Footer"
              elem="LegalNotes"
              role="button"
              tabIndex="0"
              onClick={ showPopup }
            >
                { __('Company data') }
            </span>
        );
    }

    renderNewsletterPanel() {
        if (window.location.pathname === '/trasporto-e-montaggio') {
            return null;
        }
        if (window.location.pathname === '/promozione-camerette-coupon') {
            return null;
        }
        if (window.location.pathname === '/promozione-camere-coupon') {
            return null;
        }
        if (window.location.pathname === '/lp-mcday2022') {
            return null;
        }
        if (window.location.pathname === '/super-saldi-arredamento') {
            return null;
        }

        if (window.location.pathname === '/dolce-casa') {
            return null;
        }

        return (
            <NewsletterCustom />
        );
    }

    renderLegalNotesPopup() {
        const legal_notes = 'legal_notes';

        return (
            <CmsPopup
              id={ LEGAL_NOTES_POPUP }
              cms_block={ legal_notes }
              blockType="Legal"
            />
        );
    }

    renderTempiDiConsegnaBanner() {
        return (
            <TempiDiConsegnaBanner />
        );
    }

    render() {
        // const { isVisibleOnMobile, device } = this.props;
        //
        // if (!isVisibleOnMobile && device.isMobile) {
        //     return null;
        // }
        //
        // if (isVisibleOnMobile && !device.isMobile) {
        //     return null;
        // }

        if (window.location.pathname === '/concorsocatanzaro') {
            return null;
        }

        const { isVisible } = this.state;

        return isVisible && (
            <footer block="Footer" aria-label="Footer">
                { (window.location.pathname !== '/black-friday') && (
                    this.renderNewsletterPanel()
                ) }
                { this.renderContent() }
                { this.renderAltro() }
                { this.renderCopyrightContent() }
                { this.renderLegalNotesPopup() }
                <ScrollToTop />
            </footer>
        );
    }
}

export default FooterComponent;
