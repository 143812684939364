/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import Gtm from 'Component/Gtm';
import NoMatch from 'Route/NoMatch';
import {
    CategoryPage,
    CmsPage,
    ProductPage,
    UrlRewrites as SourceUrlRewrites
} from 'SourceRoute/UrlRewrites/UrlRewrites.component';

import {
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_NOTFOUND,
    TYPE_PRODUCT
} from './UrlRewrites.config';

export {
    ProductPage,
    CategoryPage,
    CmsPage
};

/** @namespace Pwa/Route/UrlRewrites/Component/UrlRewritesComponent */
export class UrlRewritesComponent extends SourceUrlRewrites {
    renderContent() {
        const { props, type } = this.props;
        const { id } = props;

        switch (type) {
        case TYPE_PRODUCT:
            return (
                <Gtm pageType="product">
                    <ProductPage { ...props } key={ id } />
                </Gtm>
            );
        case TYPE_CMS_PAGE:
            return (
                <Gtm pageType="other">
                    <CmsPage { ...props } />
                </Gtm>
            );

        case TYPE_CATEGORY:
            return (
                <Gtm pageType="listing">
                    <CategoryPage { ...props } />
                </Gtm>
            );
        case TYPE_NOTFOUND:
            // EVENTO GA4
            window.dataLayer.push({
                event: 'error',
                error_type: '404'
            });

            return <NoMatch { ...props } />;
        default:
            return this.renderDefaultPage();
        }
    }
}

export default UrlRewritesComponent;
