import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Highlight } from 'react-instantsearch-dom';

import './SearchResultCategory.style.scss';

/** @namespace Pwa/Component/AlgoliaInstantSearch/SearchResultCategory/Component/SearchResultCategoryComponent */
export class SearchResultCategoryComponent extends PureComponent {
    static propTypes = {
        hit: PropTypes.shape({
            objectID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            product_count: PropTypes.number.isRequired
        }).isRequired
    };

    render() {
        const { hit } = this.props;
        const { url: finalUrl, product_count } = hit;

        return (
            <a block="SearchResultCategory" href={ finalUrl }>
                <Highlight attribute="path" hit={ hit } />
                <span block="SearchResultCategory" elem="productCount">
                    { `(${product_count})` }
                </span>
            </a>
        );
    }
}

export default SearchResultCategoryComponent;
