/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    NoMatch as SourceNoMatch
} from 'SourceRoute/NoMatch/NoMatch.component';

/** @namespace Pwa/Route/NoMatch/Component/NoMatchComponent */
export class NoMatchComponent extends SourceNoMatch {
    componentDidMount() {
        super.componentDidMount();
        document.head.ariaHidden = '404';
    }

    componentWillUnmount() {
        document.head.removeAttribute('aria-hidden');
    }
}

export default NoMatchComponent;
