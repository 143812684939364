/* eslint-disable no-restricted-globals */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import FieldInput from '@scandipwa/scandipwa/src/component/FieldInput';
import { debounce } from 'lodash';

import { Field as SourceField } from 'SourceComponent/Field/Field.component';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

import './Field.override.style';

/** @namespace Pwa/Component/Field/Component/FieldComponent */
export class FieldComponent extends SourceField {
    __construct(props) {
        super.__construct(props);
        this.state = {
            stateValue: props.value || 0
        };

        this.debouncedOnChangeCartQty = debounce(this.onChangeCartQty.bind(this), 1500);
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props;
        const limitedItem = BrowserDatabase.getItem('limitedItemCartItem');
        if (limitedItem) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ stateValue: value });
        }
        if (prevProps.value !== value) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ stateValue: value });
        }
    }

    onChangeFloor = (e) => {
        const {
            handleChange
        } = this.props;

        const inputValue = e.target.value;

        // Se il campo è vuoto, imposta il valore a zero
        if (inputValue === '') {
            handleChange('0');
        } else if (inputValue.length === 2 && inputValue.startsWith('0')) {
            const processedValue = inputValue.replace(/^0+/, ''); // Rimuovi lo zero
            handleChange(processedValue);
        } else {
            handleChange(inputValue);
        }
    };

    onInputChange = (e) => {
        const { value } = e.target;
        const parsedValue = parseFloat(value);
        const current = e.target;

        if (!isNaN(parsedValue)) {
            this.setState({ stateValue: parsedValue });
            this.debouncedOnChangeCartQty(parsedValue, current);
        } else {
            const current = e.currentTarget;
            setTimeout(() => {
                current.select();
            },
            200);
        }
    };

    onChangeCartQty(inputValue, current) {
        const {
            handleChange, min, max
        } = this.props;

        current.blur();

        if (inputValue < min) {
            this.setState({ stateValue: min });
            handleChange(min);
        } else if (inputValue > max) {
            this.setState({ stateValue: max });
            handleChange(max);
        } else {
            handleChange(inputValue);
        }
    }

    selectAll = (e) => {
        e.currentTarget.select();
    };

    renderTypeNumber() {
        const {
            min, max, onKeyEnterDown, handleChange, numberFromDC, numberFromCart, value
        } = this.props;

        const { stateValue } = this.state;

        if (numberFromDC) {
            return (
                <FieldInput
                  { ...this.props }
                  type="number"
                  onChange={ this.onChangeFloor }
                  onKeyDown={ onKeyEnterDown }
                  aria-label={ __('Value') }
                />
            );
        }

        if (numberFromCart) {
            return (
                <>
                  <FieldInput
                    { ...this.props }
                    type="number"
                    ref={ this.inputRef }
                    value={ stateValue }
                    onChange={ this.onInputChange }
                    onClick={ this.selectAll }
                    aria-label={ __('Value') }
                  />
                  <button
                    disabled={ +value === max }
                      // eslint-disable-next-line react/jsx-no-bind
                    onClick={ () => {
                        handleChange(+value + 1);
                        this.setState((prevState) => ({
                            stateValue: parseFloat(prevState.stateValue) + 1
                        }));
                    } }
                    aria-label={ __('Add') }
                  />
                  <button
                    disabled={ +value === min }
                      // eslint-disable-next-line react/jsx-no-bind
                    onClick={ () => {
                        handleChange(+value - 1);
                        this.setState((prevState) => ({
                            stateValue: parseFloat(prevState.stateValue) - 1
                        }));
                    } }
                    aria-label={ __('Subtract') }
                  />
                </>
            );
        }

        return (
            <>
                <FieldInput
                  { ...this.props }
                  type="number"
                  readOnly
                    // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (e) => handleChange(e.target.value, false) }
                  onKeyDown={ onKeyEnterDown }
                  aria-label={ __('Value') }
                />
                <button
                  disabled={ +value === max }
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value + 1) }
                  aria-label={ __('Add') }
                />
                <button
                  disabled={ +value === min }
                    // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value - 1) }
                  aria-label={ __('Subtract') }
                />
            </>
        );
    }
}

export default FieldComponent;
