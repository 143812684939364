/* eslint-disable no-debugger */
/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable max-lines */
import { CART_TOTALS } from '@scandipwa/scandipwa/src/store/Cart/Cart.reducer';
import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase';
import Cookie from 'js-cookie';

import CartQuery from 'Query/Cart.query';
import RebuildCartQuery from 'Query/RebuildCart.query';
import {
    CartDispatcher as SourceCartDispatcher
} from 'SourceStore/Cart/Cart.dispatcher';
import { updateTotals } from 'Store/Cart/Cart.action';
import { resetLoading } from 'Store/Configuratore/Configuratore.action';
import MyAccountDispatcher from 'Store/MyAccount/MyAccount.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';
import { changeLoader } from 'Store/Warehouse/Warehouse.action';
import { isSignedIn } from 'Util/Auth';
import { getCartItemById } from 'Util/Cart/GetCartItemById';
import { getGuestQuoteId, setGuestQuoteId } from 'Util/Cart/Token';
import { getExtensionAttributes } from 'Util/Product';
import { fetchMutation, fetchQuery, getErrorMessage } from 'Util/Request';

export const GTMDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/GTM/GTM.dispatcher'
);

/** @namespace Pwa/Store/Cart/Dispatcher/CartDispatcher */
export class CartDispatcher extends SourceCartDispatcher {
    async recoverBrokenCart(dispatch) { // MCT-1357
        // dispatch(showNotification('error',
        //     __('One error occurred. Your cart has been restored. Please try again.')));
        dispatch(resetLoading());
        if (isSignedIn()) {
            return MyAccountDispatcher.logout(false, dispatch);
        }

        return this.createGuestEmptyCart(dispatch);
    }

    /**
     * Refresh cart can be uset to manually force the cart refresh (see MyAccount dispatcher)
     * @param dispatch
     * @returns {Promise<void>}
     */
    async refreshCart(dispatch, gqid) {
        const loggedIn = isSignedIn();
        const quoteId = gqid || getGuestQuoteId();

        console.log(`Refreshing cart ${ quoteId }`);
        const { cartData = {} } = await fetchQuery(
            CartQuery.getCartQuery(
                !loggedIn && quoteId
            )
        );

        dispatch(changeLoader(false));
        return this._updateCartData(cartData, dispatch);
    }

    /**
     * Optimized version of updateInitialCartData.
     * ScandiPWA starts a backend cart call at every new visit
     * This fix avoids non needed cart calls
     *
     * @param dispatch
     * @returns {Promise<null|void|*|string|undefined>}
     */
    async updateInitialCartData(dispatch) {
        try {
            // eslint-disable-next-line fp/no-let
            let maskedId = null;
            if (window.location.pathname === '/cart') {
                maskedId = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop)
                }).gqid;
                if (maskedId) {
                    setGuestQuoteId(maskedId);
                }
            }

            const loggedIn = isSignedIn();
            const quoteId = getGuestQuoteId();
            const cartTotals = BrowserDatabase.getItem(CART_TOTALS);

            if ((!cartTotals?.subtotal) && !maskedId) {
                console.log('Skipping cart check - code:ST0');
                return null; // Do not check cart if subtotal is zero
            }

            if ((!quoteId && !loggedIn) && !maskedId) {
                console.log('Skipping cart check - code:GU');
                return null; // Avoid creating an empty cart
            }
            // dispatch(changeLoader(false));

            return this.refreshCart(dispatch, maskedId);
        } catch (error) {
            return this.recoverBrokenCart(dispatch);
        }
    }

    /**
     * Crete emtpy cart (see success page)
     * @param dispatch
     * @returns {Promise<string|null>}
     */
    async createEmptyCart(dispatch) {
        try {
            const {
                createEmptyCart: quoteId = ''
            } = await fetchMutation(CartQuery.getCreateEmptyCartMutation());

            setGuestQuoteId(quoteId);
            this._updateCartData({}, dispatch);
            return quoteId;
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));
            return null;
        }
    }

    async addProductToCart(dispatch, options, cont = 0) {
        // Create an empty cart if missing
        const isCustomerSignedIn = isSignedIn();
        if (!isCustomerSignedIn && !getGuestQuoteId()) {
            await this.createGuestEmptyCart(dispatch);
        }

        const {
            product,
            quantity,
            productOptionsData,
            buyRequest
        } = options;

        const {
            sku,
            type_id: product_type
        } = product;

        const {
            productOptions,
            productOptionsMulti,
            downloadableLinks
        } = productOptionsData || {};

        const productToAdd = {
            sku,
            product_type,
            quantity,
            product_option: {
                buy_request: buyRequest,
                extension_attributes: getExtensionAttributes(
                    {
                        ...product,
                        productOptions,
                        productOptionsMulti,
                        downloadableLinks
                    }
                )
            }
        };

        if (this._canBeAdded(options)) {
            try {
                const isCustomerSignedIn = isSignedIn();
                const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

                if (!isCustomerSignedIn && !guestQuoteId) {
                    return Promise.reject();
                }

                const cartItems = BrowserDatabase.getItem(CART_TOTALS);
                const { saveCartItem: { cartData = {} } = {} } = await fetchMutation(
                    CartQuery.getSaveCartItemMutation(productToAdd, guestQuoteId)
                );

                const oldSkuQty = cartItems.items?.find((elem) => elem.sku === productToAdd.sku);
                const newSkuQty = cartData.items?.find((elem) => elem.sku === productToAdd.sku);

                if (oldSkuQty && newSkuQty && oldSkuQty.qty === newSkuQty.qty) {
                    BrowserDatabase.setItem(true, 'limitedItem');
                }

                return this._updateCartData(cartData, dispatch);
            } catch (error) {
                if (error[0] && error[0].extensions?.category === 'internal') {
                    await this.recoverBrokenCart(dispatch);
                    // eslint-disable-next-line no-magic-numbers
                    if (cont < 3) {
                        this.addProductToCart(dispatch, options, cont + 1);
                    }

                    return Promise.reject();
                }

                dispatch(showNotification('error', getErrorMessage(error)));
                return Promise.reject();
            }
        }

        return Promise.reject();
    }

    async removeProductFromCart(dispatch, item_id) {
        try {
            const isCustomerSignedIn = isSignedIn();
            const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

            if (!isCustomerSignedIn && !guestQuoteId) {
                return null;
            }

            const cartItem = getCartItemById(item_id);

            const { removeCartItem: { cartData = {} } = {} } = await fetchMutation(
                CartQuery.getRemoveCartItemMutation(item_id, guestQuoteId)
            );

            GTMDispatcher.then(
                ({ default: dispatcher }) => dispatcher.pushRemoveFromCart(dispatch, cartItem)
            );

            this._updateCartData(cartData, dispatch);
            return cartData;
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));
            await this.recoverBrokenCart(dispatch);
            return null;
        }
    }

    async changeItemQty(dispatch, options) {
        const { item_id, quantity, sku } = options;
        const oldCartItem = getCartItemById(item_id);

        try {
            const isCustomerSignedIn = isSignedIn();
            const guestQuoteId = !isCustomerSignedIn && getGuestQuoteId();

            if (!isCustomerSignedIn && !guestQuoteId) {
                return Promise.reject();
            }

            const cartItems = BrowserDatabase.getItem(CART_TOTALS);

            const { saveCartItem: { cartData = {} } = {} } = await fetchMutation(
                CartQuery.getSaveCartItemMutation(
                    { sku, item_id, quantity },
                    guestQuoteId
                )
            );

            const oldSkuQty = cartItems.items?.find((elem) => elem.sku === sku);
            const newSkuQty = cartData.items?.find((elem) => elem.sku === sku);

            if (oldSkuQty && newSkuQty && oldSkuQty.qty === newSkuQty.qty) {
                BrowserDatabase.setItem(true, 'limitedItemCartItem');
            }

            if (quantity > oldCartItem.qty) {
                // pushAddToCart(oldCartItem.product, 'cart');
                GTMDispatcher.then(
                    ({ default: dispatcher }) => dispatcher.pushAddToCart(dispatch, oldCartItem.product, 'cart')
                );
            } else {
                // EVENTO GA4
                window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
                window.dataLayer.push({
                    event: 'remove_from_cart',
                    ecommerce: {
                        value: oldCartItem.price,
                        currency: 'EUR',
                        items: [
                            {
                                item_id: oldCartItem.product.sku,
                                item_name: oldCartItem.product.name,
                                affiliation: 'store online',
                                currency: 'EUR',
                                discount: oldCartItem.product?.price_range?.maximum_price?.discount?.amount_off,
                                index: 0,
                                item_brand: 'Mondo Convenienza',
                                item_category: oldCartItem.product?.attributes?.mc_techspecs_template.attribute_value,
                                item_list_name: oldCartItem.product?.attributes?.mc_techspecs_template.attribute_value ? `Categoria ${ oldCartItem.product?.attributes?.mc_techspecs_template.attribute_value }` : undefined,
                                price: oldCartItem.price,
                                quantity: quantity
                            }
                        ]
                    }
                });
                oldCartItem.qty = 1;
                // pushRemoveFromCart(oldCartItem);
                GTMDispatcher.then(
                    ({ default: dispatcher }) => dispatcher.pushRemoveFromCart(dispatch, oldCartItem)
                );
            }

            return this._updateCartData(cartData, dispatch);
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));
            await this.recoverBrokenCart(dispatch);
            return Promise.reject();
        }
    }

    async rebuildCart(dispatch) {
        try {
            const quoteId = await this._getGuestQuoteId(dispatch);
            const failedCartData = Cookie.get('mc_failed_cart');
            if (!failedCartData) {
                return;
            }
            Cookie.remove('mc_failed_cart');
            await fetchMutation(RebuildCartQuery.getMutation(JSON.parse(failedCartData), quoteId));
            const { cartData = {} } = await fetchQuery(
                CartQuery.getCartQuery(
                    !isSignedIn() && quoteId
                )
            );

            dispatch(updateTotals(cartData));
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));
        }
    }
}

export default new CartDispatcher();
