/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './ScrollToTop.style';

/** @namespace Pwa/Component/ScrollToTop/Component/ScrollToTopComponent */
export class ScrollToTopComponent extends PureComponent {
    static propTypes = {
        scrollToTop: PropTypes.func.isRequired,
        isVisible: PropTypes.bool
    };

    static defaultProps = {
        isVisible: false
    };

    render() {
        const { isVisible, scrollToTop } = this.props;
        return (
            <div
              block="ScrollToTop"
              onClick={ () => scrollToTop() }
              onKeyDown={ () => scrollToTop() }
              role="button"
              tabIndex="0"
              mods={ { isVisible } }
            >
                <i className="fa fa-chevron-up" />
            </div>
        );
    }
}

export default ScrollToTopComponent;
