import { BrowserDatabase as SourceBrowserDatabase } from 'SourceUtil/BrowserDatabase/BrowserDatabase.js';

/* @namespace Pwa/Util/BrowserDatabase/BrowserDatabase */
export class BrowserDatabase extends SourceBrowserDatabase {
    exceptions = [
        'guest_quote_id',
        'cart_totals',
        'mc_warehouse',
        'mc_warehouse_custom',
        'mc_type',
        'config',
        'addressField',
        'addressZipCode',
        'warehouseId',
        'popup_mcType',
        'customer',
        'user_firstname',
        'user_lastname',
        'auth_token',
        'foundCity'
    ];

    setItem(data, location, expiration) {
        try {
            localStorage.setItem(
                location,
                JSON.stringify({
                    data,
                    expiration,
                    createdAt: Date.now()
                })
            );
        } catch {
            const keys = Object.keys(localStorage);
            keys.forEach((key) => {
                if (!this.exceptions.includes(key)) {
                    localStorage.removeItem(key);
                }
            });
        }
    }
}

export default new BrowserDatabase();
