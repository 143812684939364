/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './CustomSearchBar.style';

/** @namespace Pwa/Component/CustomSearchBar/Component/CustomSearchBarComponent */
export class CustomSearchBarComponent extends PureComponent {
    static propTypes = {
        search: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        inputRef: PropTypes.object.isRequired,
        value: PropTypes.string.isRequired,
        onSearchSubmit: PropTypes.func.isRequired
    };

    render() {
        const {
            search, value, reset, onSearchSubmit, inputRef
        } = this.props;

        return (
            <div block="CustomSearchBar" elem="Wrappper">
                <form noValidate role="search" block="CustomSearchBar" elem="Form" onSubmit={ (e) => onSearchSubmit(e) } onReset={ (e) => reset(e) }>
                    <input
                      block="CustomSearchBar"
                      elem="Input"
                      type="search"
                      placeholder={ __('Search a product...') }
                      value={ value }
                      ref={ inputRef }
                      onChange={ (e) => search(e) }
                      onClick={ (e) => search(e) }
                      onFocus={ (e) => search(e) }
                    />
                    <button type="reset" aria-label="Reset" title="Reset your search query." block="CustomSearchBar" elem="Reset" mods={ { isVisible: (value.length > 0) } } />
                    <button type="submit" title="Submit your search query." block="CustomSearchBar" elem="Submit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 40 40" block="CustomSearchBar" elem="Icon">
                            <path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z" />
                        </svg>
                    </button>

                </form>
            </div>
        );
    }
}

export default CustomSearchBarComponent;
