import { FLASHSALEPRODUCT_ACTION_TYPE } from './FlashSaleProduct.action';

/** @namespace Pwa/Store/FlashSaleProduct/Reducer/getInitialState */
export const getInitialState = () => ({
    flashSaleProduct: []
});

/** @namespace Pwa/Store/FlashSaleProduct/Reducer/FlashSaleProductReducer */
export const FlashSaleProductReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case FLASHSALEPRODUCT_ACTION_TYPE:
        const { payload } = action;

        return {
            ...state,
            flashSaleProduct: payload
        };

    default:
        return state;
    }
};

export default FlashSaleProductReducer;
