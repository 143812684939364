/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Link from 'Component/Link';

import './PrivacyCheckboxLabel.style';

/** @namespace Pwa/Component/PrivacyCheckboxLabel/Component/PrivacyCheckboxLabelComponent */
export class PrivacyCheckboxLabelComponent extends PureComponent {
    static propTypes = {
        storeCode: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        isRequired: PropTypes.bool,
        isNlCheckbox: PropTypes.bool
    };

    // isNlCheckbox è true solo per le checkbox della sottoscrizione alla newsletter
    static defaultProps = {
        isRequired: false,
        isNlCheckbox: false
    };

    render() {
        const {
            storeCode, label, link, isRequired, isNlCheckbox
        } = this.props;
        const status = isRequired ? __('(Obbligatorio)') : __('(Facoltativo)');
        const url = (storeCode === 'default') ? '/privacy' : '/informacion-privacidad';
        // no space in italian
        const space = __('');
        const { pathname } = window.location;
        return (
            <div>
                { label }
                { link && (
                    <>
                        { isNlCheckbox && space }
                        <Link
                          to={ url }
                          block="PrivacyCheckbox"
                          elem="Link"
                          target={ pathname === '/newsletter-mondo-convenienza' ? '_blank' : '_self' }
                        >
                            { link }
                        </Link>
                        { isNlCheckbox && '.' }
                        &nbsp;
                        { status }
                    </>
                ) }
            </div>
        );
    }
}

export default PrivacyCheckboxLabelComponent;
