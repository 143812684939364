import PropTypes from 'prop-types';

import CmsBlock from 'Component/CmsBlock/CmsBlock.component';
import Loader from 'Component/Loader';
import {
    CmsBlockContainer as SourceCmsBlockContainer
} from 'SourceComponent/CmsBlock/CmsBlock.container';

/** @namespace Pwa/Component/CmsBlock/Container/CmsBlockContainer */
export class CmsBlockContainer extends SourceCmsBlockContainer {
    static propTypes = {
        ...SourceCmsBlockContainer.propTypes,
        onLoad: PropTypes.func,
        showLoader: PropTypes.bool,
        placeHolderSize: PropTypes.shape({
            width: PropTypes.string,
            height: PropTypes.string
        })
    };

    static defaultProps = {
        onLoad: (_identifier) => {},
        showLoader: false,
        placeHolderSize: {
            width: '',
            height: ''
        }
    };

    componentDidUpdate(oldProps) {
        const { identifier: old_identifier } = oldProps;
        const { identifier: new_identifier, onLoad } = this.props;
        if (old_identifier !== new_identifier) {
            this._getCmsBlock();
            onLoad(new_identifier);
        }
    }

    render() {
        const { showLoader, placeHolderSize } = this.props;
        const { cmsBlock } = this.state;

        if (!cmsBlock.identifier && Object.keys(cmsBlock).length > 0) {
            if (placeHolderSize.width || placeHolderSize.height) {
                return <div style={ placeHolderSize } className="CmsBlock-Loader" />;
            }

            if (showLoader) {
                return <Loader isLoading />;
            }
        }

        return (
            <CmsBlock
              { ...this.containerProps() }
            />
        );
    }
}

export default CmsBlockContainer;
