/** @namespace Pwa/Util/PromoPopupSwitch/shouldShowTopbar */
import { BLACK_FRIDAY_WARMUP, getPhase } from 'Util/BlackFridayPhase';

/** @namespace Pwa/Util/PromoPopupSwitch/shouldShowTopbar */
export const shouldShowTopbar = (routerProps) => {
    const { location: { pathname } } = routerProps;

    if (getPhase() === BLACK_FRIDAY_WARMUP) {
        return ['/offerte/bf2021.html', '/ofertas/bf2021.html'].includes(pathname);
    }

    return true;
};
