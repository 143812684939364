import { SUCCESS_PAGE_LINKS_ACTION_TYPE } from './SuccessPageLinks.action';

/** @namespace Pwa/Store/SuccessPageLinks/Reducer/getInitialState */
export const getInitialState = () => ({
    successPageLinks: {},
    isLoading: true
});

/** @namespace Pwa/Store/SuccessPageLinks/Reducer/SuccessPageLinksReducer */
export const SuccessPageLinksReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case SUCCESS_PAGE_LINKS_ACTION_TYPE:
        const { payload } = action;

        return {
            ...state,
            successPageLinks: payload,
            isLoading: false
        };

    default:
        return state;
    }
};

export default SuccessPageLinksReducer;
