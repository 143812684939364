// TODO rename
export const ACTIVATE_PROMO_TOPNAV = 'ACTIVATE_PROMO_TOPNAV';
export const DEACTIVATE_PROMO_TOPNAV = 'DEACTIVATE_PROMO_TOPNAV';

// TODO rename
/** @namespace Pwa/Store/Promo/Action/activateTopNav */
export const activateTopNav = () => {
    document.documentElement.classList.add('PromoPopUpVisible');
    return {
        type: ACTIVATE_PROMO_TOPNAV
    };
};
/** @namespace Pwa/Store/Promo/Action/deactivateTopNav */
export const deactivateTopNav = () => {
    document.documentElement.classList.remove('PromoPopUpVisible');
    return {
        type: DEACTIVATE_PROMO_TOPNAV
    };
};
