/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-lines */
import Popup from '@scandipwa/scandipwa/src/component/Popup';
import PropTypes from 'prop-types';

import ClickOutside from 'Component/ClickOutside';
import IfStore from 'Component/IfStore';
import Link from 'Component/Link';
import McMenu from 'Component/McMenu';
import { DEFAULT_STATE_NAME } from 'Component/NavigationAbstract/NavigationAbstract.config';
import OfflineNotice from 'Component/OfflineNotice';
import { BOOK_POPUP_BEDROOM, BOOK_POPUP_KITCHEN } from 'Component/ProductActions/ProductActions.config';
import SearchField from 'Component/SearchField';
import {
    CartOverlay,
    Header as SourceHeader,
    MyAccountOverlay
} from 'SourceComponent/Header/Header.component';
import { DeviceType } from 'Type/Device';
import { TotalsType } from 'Type/MiniCart';
import { isSignedIn } from 'Util/Auth';
import { isMobile, startMobile } from 'Util/mcMobileCheck';

import {
    CART_OVERLAY,
    CUSTOMER_WISHLIST,
    MENU,
    MENU_SUBCATEGORY,
    POPUP,
    SEARCH
} from './Header.config';

import './Header.override.style';

/** @namespace Pwa/Component/Header/Component/resetHeader */
export const resetHeader = (list) => {
    const data = list;
    Object.keys(list).forEach((key) => {
        if (![MENU, MENU_SUBCATEGORY, POPUP].includes(key)) {
            data[key] = {
                logo: true,
                burger: true,
                storelocator: true,
                account: true,
                minicart: true,
                search: true
            };
        }
    });

    return data;
};

export {
    CartOverlay,
    MyAccountOverlay
};

/** @namespace Pwa/Component/Header/Component/HeaderComponent */
export class HeaderComponent extends SourceHeader {
    static propTypes = {
        navigationState: PropTypes.object.isRequired,
        cartTotals: TotalsType.isRequired,
        onBackButtonClick: PropTypes.func.isRequired,
        onCloseButtonClick: PropTypes.func.isRequired,
        onSearchBarFocus: PropTypes.func.isRequired,
        onClearSearchButtonClick: PropTypes.func.isRequired,
        onMyAccountButtonClick: PropTypes.func.isRequired,
        onSearchBarChange: PropTypes.func.isRequired,
        onClearButtonClick: PropTypes.func.isRequired,
        isWishlistLoading: PropTypes.bool.isRequired,
        onEditButtonClick: PropTypes.func.isRequired,
        onMinicartButtonClick: PropTypes.func.isRequired,
        onOkButtonClick: PropTypes.func.isRequired,
        onCancelButtonClick: PropTypes.func.isRequired,
        onSearchOutsideClick: PropTypes.func.isRequired,
        onMyAccountOutsideClick: PropTypes.func.isRequired,
        onMinicartOutsideClick: PropTypes.func.isRequired,
        onMenuButtonClick: PropTypes.func.isRequired,
        isClearEnabled: PropTypes.bool.isRequired,
        searchCriteria: PropTypes.string.isRequired,
        shareWishlist: PropTypes.func.isRequired,
        header_logo_src: PropTypes.string,
        logo_alt: PropTypes.string,
        logo_height: PropTypes.number,
        logo_width: PropTypes.number,
        isLoading: PropTypes.bool,
        showMyAccountLogin: PropTypes.bool,
        isCheckout: PropTypes.bool.isRequired,
        onSignIn: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired,
        activeOverlay: PropTypes.string.isRequired,
        device: DeviceType.isRequired,
        isVariant: PropTypes.bool,
        doLogout: PropTypes.func.isRequired
    };

    static defaultProps = {
        logo_alt: 'ScandiPWA logo',
        logo_height: 25,
        logo_width: 200,
        showMyAccountLogin: false,
        header_logo_src: '',
        isLoading: true,
        isVariant: false
    };

    renderMap = {
        cancel: this.renderCancelButton.bind(this),
        back: this.renderBackButton.bind(this),
        close: this.renderCloseButton.bind(this),
        share: this.renderShareWishListButton.bind(this),
        burger: this.renderBurgerMenuToggle.bind(this),
        // title: this.renderTitle.bind(this),
        logo: this.renderLogo.bind(this),
        search: this.renderSearchField.bind(this),
        storelocator: this.renderStoreLocator.bind(this),
        info: this.renderInfo.bind(this),
        account: this.renderAccount.bind(this),
        catalogo: this.renderCatalogo.bind(this),
        faidate: this.renderFaiDaTe.bind(this), // FAI DA TE
        minicart: this.renderMinicart.bind(this),
        clear: this.renderClearButton.bind(this),
        edit: this.renderEditButton.bind(this),
        ok: this.renderOkButton.bind(this)
    };

    stateMap = {
        ...resetHeader(this.stateMap),
        [MENU]: {
            close: true,
            logo: true
        },
        [MENU_SUBCATEGORY]: {
            back: true,
            close: true,
            search: true
        },
        [POPUP]: this.stateMap[DEFAULT_STATE_NAME]
    };

    state = {
        openToggle: false
    };

    renderFaiDaTe() {
        const {
            isCheckout,
            isVariant
        } = this.props;

        if (isCheckout || startMobile()) {
            return null;
        }

        return (
            <>
                { /* <IfStore storeCode="default">
                { !isSignedIn() && (
                    <div
                      key="faidate"
                      aria-label={ __('Do it yourself') }
                      block="Header"
                      elem="FaiDaTe"
                      mods={ { isVariant } }
                    >
                        <div
                          block="Header"
                          elem="FaiDaTeWrapper"
                        >
                            <a
                              href={ __('https://servizi.mondoconv.it/ordini/') }
                              target="_blank"
                              linkFaiDaTe
                              block="Header"
                              elem="Link"
                              rel="noreferrer"
                            >
                                <span block="Header" elem="FaiDaTeImage" />
                                <span
                                  block="Header"
                                  elem="FaiDaTeTitle"
                                  mods={ { isVariant } }
                                >
                                    { __('Do it yourself') }
                                </span>
                            </a>
                        </div>
                    </div>
                ) }
            </IfStore> */ }
                <IfStore storeCode="spain">
                    <div
                      key="faidate"
                      aria-label={ __('Do it yourself') }
                      block="Header"
                      elem="FaiDaTe"
                      mods={ { isVariant } }
                    >
                        <div
                          block="Header"
                          elem="FaiDaTeWrapper"
                        >
                            <a
                              href={ __('https://servizi.mondoconv.it/ordini/') }
                              target="_blank"
                              linkFaiDaTe
                              block="Header"
                              elem="Link"
                              rel="noreferrer"
                            >
                                <span block="Header" elem="FaiDaTeImage" />
                                <span
                                  block="Header"
                                  elem="FaiDaTeTitle"
                                  mods={ { isVariant } }
                                >
                                    { __('Do it yourself') }
                                </span>
                            </a>
                        </div>
                    </div>
                </IfStore>
            </>
        );
    }

    renderStoreLocator(isVisible = false) {
        const {
            isCheckout,
            isVariant,
            storeCode
        } = this.props;

        if (isMobile() && isSignedIn() && storeCode === 'default') {
            return null;
        }

        if (isCheckout || (startMobile() && !isVisible)) {
            return null;
        }

        return (
            <div
              key="store-locator"
              aria-label={ __('Shop') }
              block="Header"
              elem="StoreLocator"
              mods={ { isVariant } }
            >
                <div
                  block="Header"
                  elem="StoreLocatorWrapper"
                >
                    <Link
                      to="punti-vendita"
                      key="punti-vendita"
                      block="Header"
                      elem="Link"
                      mix={ { block: 'Header', elem: 'StoreLocatorIcon' } }
                      aria-label={ __('Shop') }
                    >
                        <span block="Header" elem="StoreLocatorImage" />
                        <span
                          block="Header"
                          elem="StoreLocatorTitle"
                          mods={ { isVariant } }
                        >
                            { __('Shops') }
                        </span>
                    </Link>
                </div>
            </div>
        );
    }

    renderBurgerMenuToggle(isVisible = false) {
        const {
            isCheckout,
            isVariant,
            onMenuButtonClick
        } = this.props;

        if (!(startMobile() && isVisible) || isCheckout) {
            return null;
        }

        return (
            <button
              tabIndex="0"
              key="burder-menu"
              aria-label={ __('Menu') }
              block="Header"
              elem="BurgerMenu"
              mods={ { isVariant } }
              onClick={ onMenuButtonClick }
            >
                <div
                  block="Header"
                  elem="BurgerIcon"
                />

            </button>
        );
    }

    renderSearchField(isVisible = false) {
        const {
            searchCriteria,
            onSearchOutsideClick,
            onSearchBarFocus,
            onSearchBarChange,
            onClearSearchButtonClick,
            navigationState: { name },
            isCheckout,
            hideActiveOverlay
        } = this.props;

        if (isCheckout || !isVisible) {
            return null;
        }

        return (
            <SearchField
              key="search"
              searchCriteria={ searchCriteria }
              onSearchOutsideClick={ onSearchOutsideClick }
              onSearchBarFocus={ onSearchBarFocus }
              onSearchBarChange={ onSearchBarChange }
              onClearSearchButtonClick={ onClearSearchButtonClick }
              isVisible={ isVisible }
              isActive={ name === SEARCH }
              hideActiveOverlay={ hideActiveOverlay }
            />
        );
    }

    renderCatalogo(isVisible = false) {
        const {
            isCheckout,
            isVariant
        } = this.props;

        if (isCheckout || (isMobile() && !isVisible)) {
            return null;
        }

        return (
            <div
              key="catalogo"
              aria-label={ __('Catalog') }
              block="Header"
              elem="Catalogo"
              mods={ { isVariant } }
            >
                <div
                  block="Header"
                  elem="CatalogoWrapper"
                >
                    <Link
                      to="catalogo"
                      key="catalogo"
                      block="Header"
                      elem="Link"
                      aria-label={ __('Catalog') }
                    >
                        <span block="Header" elem="CatalogoImage" />
                        <span
                          block="Header"
                          elem="CatalogoTitle"
                          mods={ { isVariant } }
                        >
                            { __('Catalog') }
                        </span>
                    </Link>
                </div>
            </div>
        );
    }

    renderAccount(isVisible = false) {
        const {
            isCheckout,
            device,
            storeCode
        } = this.props;

        // on mobile hide button if not in checkout
        // if (device.isMobile && !isCheckout) {
        //     return null;
        // }

        if (isMobile() && storeCode === 'default') {
            if (isSignedIn()) {
                const name = JSON.parse(localStorage.getItem('user_firstname'))?.data || '';
                const domain = (window.location.hostname === 'mc.local' || window.location.hostname === 'staging2.mondoconv.it') ? 'https://account-sg.mondoconv.it/' : 'https://account.mondoconv.it/';
                return (
                    <>
                        <p
                          className="MyAccount-Welcome"
                          onClick={ () => {
                              window.location.replace(domain);
                          } }
                        >
                            { __('Hello') }
                            <br />
                            <b>{ name }</b>
                        </p>
                        <div
                          aria-label="My account"
                          block="Header"
                          elem="MyAccount"
                          key="mcAccount"
                        >
                            <button
                              block="Header"
                              elem="MyAccountWrapper"
                              tabIndex="0"
                              onClick={ () => window.location.replace(domain) }
                              aria-label="Open my account"
                              id="myAccount"
                            >
                                <span block="Header" elem="AccountImage" />
                                <span
                                  block="Header"
                                  elem="MyAccountTitle"
                                >
                                    { __('Account') }
                                </span>

                            </button>
                        </div>
                    </>
                );
            }

            if (window.location.pathname === '/menu') {
                return (
                    <button
                      className="Menu-SignIn"
                      onClick={ () => {
                          const domain = (window.location.hostname === 'mc.local' || window.location.hostname === 'staging2.mondoconv.it') ? 'https://account-sg.mondoconv.it/login?mode=redirect' : 'https://account.mondoconv.it/login?mode=redirect';
                          this.setState({ isLoading: true });
                          const path = `&redirectUrl=${window.location.pathname}`;
                          window.location.replace(domain + path);
                      } }
                    >
                        { __('Sign in') }
                    </button>
                );
            }

            return (
                <div
                  aria-label="My account"
                  block="Header"
                  elem="MyAccount"
                  key="mcAccount"
                >
                    { this.renderAccountButton(isVisible) }
                </div>
            );
        }

        if ((isCheckout && isSignedIn()) || (!isVisible)) {
            return null;
        }

        return (
            <div
              aria-label="My account"
              block="Header"
              elem="MyAccount"
              key="mcAccount"
            >
                { this.renderAccountButton(isVisible) }
            </div>
        );
    }

    renderAccountButton(isVisible) {
        const {
            onMyAccountButtonClick, device
        } = this.props;

        const domain = (window.location.hostname === 'mc.local' || window.location.hostname === 'staging2.mondoconv.it') ? 'https://account-sg.mondoconv.it/login?mode=redirect' : 'https://account.mondoconv.it/login?mode=redirect';

        return (
            <>
                <IfStore storeCode="default">
                    { !isSignedIn() && (
                        <button
                          block="Header"
                          elem="MyAccountWrapper"
                          tabIndex="0"
                          onClick={ () => {
                              this.setState({ isLoading: true });
                              const path = `&redirectUrl=${window.location.pathname}`;
                              window.location.replace(domain + path);
                          } }
                          aria-label="Open my account"
                          id="myAccount"
                        >
                            <span block="Header" elem="AccountImage" />
                            <span
                              block="Header"
                              elem="MyAccountTitle"
                            >
                                { __('Account') }
                            </span>

                        </button>
                    ) }
                    { isSignedIn() && device.isMobile && (
                        <button
                          block="Header"
                          elem="MyAccountWrapper"
                          tabIndex="0"
                          onClick={ () => window.location.replace(domain) }
                          aria-label="Open my account"
                          id="myAccount"
                        >
                            <span block="Header" elem="AccountImage" />
                            <span
                              block="Header"
                              elem="MyAccountTitle"
                            >
                                { __('Account') }
                            </span>

                        </button>
                    ) }
                </IfStore>
                <IfStore storeCode="spain">
                    <button
                      block="Header"
                      elem="MyAccountWrapper"
                      tabIndex="0"
                      onClick={ onMyAccountButtonClick }
                      aria-label="Open my account"
                      id="myAccount"
                    >
                        <span block="Header" elem="AccountImage" />
                        <span
                          block="Header"
                          elem="MyAccountTitle"
                        >
                            { __('Account') }
                        </span>

                    </button>
                </IfStore>
            </>
        );
    }

    renderMinicart(isVisible = false) {
        const {
            onMinicartOutsideClick,
            isCheckout,
            device
        } = this.props;

        if (isCheckout || !isVisible) {
            return null;
        }

        return (
            <ClickOutside
              onClick={ onMinicartOutsideClick }
              key="minicart"
            >
                <div
                  block="Header"
                  elem="Button"
                  mods={ { isVisible, type: 'minicart' } }
                >
                    { this.renderMinicartButton() }
                    { this.renderMinicartOverlay() }
                </div>
            </ClickOutside>
        );
    }

    renderMinicartButton() {
        const {
            onMinicartButtonClick
        } = this.props;

        return (
            <button
              block="Header"
              elem="MinicartButtonWrapper"
              tabIndex="0"
              onClick={ onMinicartButtonClick }
            >
                <span
                  block="Header"
                  elem="MinicartTitle"
                >
                    { __('Cart') }
                </span>
                <span
                  aria-label="Minicart"
                  block="Header"
                  elem="MinicartImage"
                />
                { this.renderMinicartItemsQty() }
            </button>
        );
    }

    renderInfo(isVisible = false) {
        const {
            isCheckout
        } = this.props;

        const isVariant = false;

        if (isCheckout || (startMobile() && !isVisible)) {
            return null;
        }

        return (
            <div
              key="info"
              aria-label={ __('Info') }
              block="Header"
              elem="Info"
              mods={ { isVariant } }
            >
                <div
                  block="Header"
                  elem="InfoWrapper"
                >
                    <Link
                      to="faq"
                      key="faq"
                      block="Header"
                      elem="Link"
                      aria-label={ __('Info') }
                    >
                        <span block="Header" elem="InfoImage" />
                        <span
                          block="Header"
                          elem="InfoTitle"
                          mods={ { isVariant } }
                        >
                            { __('Info') }
                        </span>
                    </Link>
                </div>
            </div>
        );
    }

    renderCustomMenu() {
        const { isCheckout } = this.props;

        if (startMobile() || isCheckout) {
            return null;
        }

        return <McMenu />;
    }

    renderWelcomeUser() {
        const { openToggle } = this.state;
        const { doLogout } = this.props;
        const name = JSON.parse(localStorage.getItem('user_firstname'))?.data || '';
        const domain = (window.location.hostname === 'mc.local' || window.location.hostname === 'staging2.mondoconv.it') ? 'https://account-sg.mondoconv.it/' : 'https://account.mondoconv.it/';
        return isSignedIn() && (
            <div className="Area-Utente">
                <div
                  className="Welcome-Button"
                  role="button"
                  onClick={ () => {
                      this.setState((prevState) => ({ openToggle: !prevState.openToggle }));
                  } }
                >
                    { __('Ciao %s!', name) }
                    { (<i className={ `fa fa-chevron-down ${openToggle ? 'up' : ''}` } />) }
                </div>
                { openToggle && (
                    <ClickOutside
                      onClick={ () => {
                          this.setState({ openToggle: false });
                      } }
                      key="toggle"
                    >
                        <div className="toggle">
                            <ul>
                                <li>
                                    <a href={ `${domain}dashboard` }>
                                        <div className="aPersonale" alt="aPersonale" />
                                        <span>{ __('AREA PERSONALE') }</span>
                                    </a>
                                </li>
                                <li>
                                    <a href={ `${domain}orders` }>
                                        <div className="ordini" alt="ordini" />
                                        <span>{ __('I MIEI ORDINI') }</span>
                                    </a>
                                </li>
                                <li>
                                    <a href={ `${domain}personalData` }>
                                        <div className="dPersonali" alt="dPersonali" />
                                        <span>{ __('DATI PERSONALI') }</span>
                                    </a>
                                </li>
                                <li>
                                    <a href={ `${domain}newsletter` }>
                                        <div className="newsletter" alt="newsletter" />
                                        <span>{ __('NEWSLETTER') }</span>
                                    </a>
                                </li>
                                <li>
                                    <a href={ `${domain}address` }>
                                        <div className="indirizzi" alt="indirizzi" />
                                        <span>{ __('I MIEI INDIRIZZI') }</span>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={ () => doLogout() }>
                                        <div className="esc" alt="esc" />
                                        <span>{ __('ESCI') }</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </ClickOutside>
                ) }
            </div>
        );
    }

    renderBookPopups() {
        const { storeCode } = this.props;
        return (
            <>
                <Popup id={ BOOK_POPUP_BEDROOM } customClass="Book">
                    <p className="title">
                        { __('Progettare la tua cameretta su misura') }
                    </p>
                    <p className="subTitle">{ __('Composizione, maniglie, finiture: puoi comporre la tua cameretta con un nostro esperto, attraverso questi canali') }</p>
                    <div className="row">
                        <div className={ `col-12 col-md-${storeCode === 'default' ? '4' : '6'}` }>
                            <div className="mc-card">
                                <div className="row no-gutters">
                                    <div className="col-3 col-md-12">
                                        <a className="mc-img-link" href="/punti-vendita">
                                            <IfStore storeCode="default">
                                                <img
                                                  className="mc-img-mob"
                                                  src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/PV_1.png"
                                                />
                                                <img
                                                  className="mc-img-desk"
                                                  src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/PV.png"
                                                />
                                            </IfStore>

                                            <IfStore storeCode="spain">
                                                <img
                                                  className="mc-img-mob"
                                                  src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/Gruppo_di_maschere_3_2x.png"
                                                />
                                                <img
                                                  className="mc-img-desk"
                                                  src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/desk-b/new-p/punto%20vendita.png"
                                                />
                                            </IfStore>
                                        </a>
                                    </div>
                                    <div className="col-9 col-md-12">
                                        <div className="mc-card-text">
                                            <div className="mc-card-text-header">
                                                <h2>{ __('Punto vendita') }</h2>
                                                <p>
                                                    { __('Oltre 40 negozi in tutta Italia') }
                                                </p>
                                            </div>
                                            <div className="mc-card-link-group">
                                                <div className="mc-card-link">
                                                    <a href="/appuntamento-venditore/index/index/step-shop">
                                                        { __('Fissa un appuntamento') }
                                                    </a>
                                                    <img
                                                      src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/desk-b/new-p/Tracciato 109.png"
                                                    />
                                                </div>
                                                <div className="mc-card-link">
                                                    <a href="/punti-vendita">{ __('Trova il tuo') }</a>
                                                    <img
                                                      src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/desk-b/new-p/Tracciato 109.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={ `col-12 col-md-${storeCode === 'default' ? '4' : '6'}` }>
                            <div className="mc-card">
                                <div className="row no-gutters">
                                    <div className="col-3 col-md-12">
                                        <a
                                          className="mc-img-link"
                                          href="/prenotazione-videoconferenza/index/index/step-room"
                                          target="_blank"
                                          rel="noopener"
                                        >
                                            <img
                                              className="mc-img-mob"
                                              src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/VIDEO_1.png"
                                            />
                                            <img
                                              className="mc-img-desk"
                                              src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/VIDEO.png"
                                            />
                                        </a>
                                    </div>
                                    <div className="col-9 col-md-12">
                                        <div className="mc-card-text">
                                            <div className="mc-card-text-header">
                                                <h2>{ __('Videochiamata') }</h2>
                                                <p>
                                                    { __('La stessa esperienza del negozio, comodamente a casa tua') }
                                                </p>
                                            </div>
                                            <div className="mc-card-link">
                                                <a
                                                  className="abtn"
                                                  href="/prenotazione-videoconferenza/index/index/step-room"
                                                  target="_blank"
                                                  rel="noopener"
                                                >
                                                    { __('Prenota una consulenza') }
                                                </a>
                                                <img
                                                  src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/desk-b/new-p/Tracciato 109.png"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <IfStore storeCode="default">
                            <div className="col-12 col-md-4">
                                <div className="mc-card">
                                    <div className="row no-gutters">
                                        <div className="col-3 col-md-12">
                                            <a
                                              className="mc-img-link"
                                              href="/come-acquistare/progetto-dolce-casa#form-dolcecasa"
                                            >
                                                <img
                                                  className="mc-img-mob"
                                                  src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/DOLCE_CASA.png"
                                                />
                                                <img
                                                  className="mc-img-desk"
                                                  src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/DOLCE-CASA.png"
                                                />
                                            </a>
                                        </div>
                                        <div className="col-9 col-md-12">
                                            <div className="mc-card-text">
                                                <div className="mc-card-text-header">
                                                    <h2>{ __('Dolce Casa') }</h2>
                                                    <p>
                                                        { __('Un esperto d’arredo a domicilio a solo 60 €') }
                                                    </p>
                                                </div>
                                                <div className="mc-card-link">
                                                    <a
                                                      className="abtn"
                                                      href="/come-acquistare/progetto-dolce-casa#form-dolcecasa"
                                                    >
                                                        { __('Scopri di più') }
                                                    </a>
                                                    <img
                                                      src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/desk-b/new-p/Tracciato 109.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </IfStore>
                    </div>
                </Popup>

                <Popup id={ BOOK_POPUP_KITCHEN } customClass="Book">
                    <p className="title">
                        { __('Progetta la tua cucina su misura') }
                    </p>
                    <p className="subTitle">{ __('Composizione, maniglie, finiture: puoi comporre la tua cucina con un nostro esperto, attraverso questi canali') }</p>
                    <div className="row">
                        <div className={ `col-12 col-md-${storeCode === 'default' ? '4' : '6'}` }>
                            <div className="mc-card">
                                <div className="row no-gutters">
                                    <div className="col-3 col-md-12">
                                        <a className="mc-img-link" href="/punti-vendita">
                                            <IfStore storeCode="default">
                                                <img
                                                  className="mc-img-mob"
                                                  src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/PV_1.png"
                                                />
                                                <img
                                                  className="mc-img-desk"
                                                  src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/PV.png"
                                                />
                                            </IfStore>

                                            <IfStore storeCode="spain">
                                                <img
                                                  className="mc-img-mob"
                                                  src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/Gruppo_di_maschere_3_2x.png"
                                                />
                                                <img
                                                  className="mc-img-desk"
                                                  src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/desk-b/new-p/punto%20vendita.png"
                                                />
                                            </IfStore>
                                        </a>
                                    </div>
                                    <div className="col-9 col-md-12">
                                        <div className="mc-card-text">
                                            <div className="mc-card-text-header">
                                                <h2>{ __('Punto vendita') }</h2>
                                                <p>
                                                    { __('Oltre 40 negozi in tutta Italia') }
                                                </p>
                                            </div>
                                            <div className="mc-card-link-group">
                                                <div className="mc-card-link">
                                                    <a href="/appuntamento-venditore/index/index/step-shop">
                                                        { __('Fissa un appuntamento') }
                                                    </a>
                                                    <img
                                                      src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/desk-b/new-p/Tracciato 109.png"
                                                    />
                                                </div>
                                                <div className="mc-card-link">
                                                    <a href="/punti-vendita">{ __('Trova il tuo') }</a>
                                                    <img
                                                      src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/desk-b/new-p/Tracciato 109.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={ `col-12 col-md-${storeCode === 'default' ? '4' : '6'}` }>
                            <div className="mc-card">
                                <div className="row no-gutters">
                                    <div className="col-3 col-md-12">
                                        <a
                                          className="mc-img-link"
                                          href="/prenotazione-videoconferenza/index/index/step-room"
                                          target="_blank"
                                          rel="noopener"
                                        >
                                            <img
                                              className="mc-img-mob"
                                              src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/VIDEO_1.png"
                                            />
                                            <img
                                              className="mc-img-desk"
                                              src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/VIDEO.png"
                                            />
                                        </a>
                                    </div>
                                    <div className="col-9 col-md-12">
                                        <div className="mc-card-text">
                                            <div className="mc-card-text-header">
                                                <h2>{ __('Videochiamata') }</h2>
                                                <p>
                                                    { __('La stessa esperienza del negozio, comodamente a casa tua') }
                                                </p>
                                            </div>
                                            <div className="mc-card-link">
                                                <a
                                                  className="abtn"
                                                  href="/prenotazione-videoconferenza/index/index/step-room"
                                                  target="_blank"
                                                  rel="noopener"
                                                >
                                                    { __('Prenota una consulenza') }
                                                </a>
                                                <img
                                                  src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/desk-b/new-p/Tracciato 109.png"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <IfStore storeCode="default">
                            <div className="col-12 col-md-4">
                                <div className="mc-card">
                                    <div className="row no-gutters">
                                        <div className="col-3 col-md-12">
                                            <a
                                              className="mc-img-link"
                                              href="/come-acquistare/progetto-dolce-casa#form-dolcecasa"
                                            >
                                                <img
                                                  className="mc-img-mob"
                                                  src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/DOLCE_CASA.png"
                                                />
                                                <img
                                                  className="mc-img-desk"
                                                  src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/DOLCE-CASA.png"
                                                />
                                            </a>
                                        </div>
                                        <div className="col-9 col-md-12">
                                            <div className="mc-card-text">
                                                <div className="mc-card-text-header">
                                                    <h2>{ __('Dolce Casa') }</h2>
                                                    <p>
                                                        { __('Un esperto d’arredo a domicilio a solo 60 €') }
                                                    </p>
                                                </div>
                                                <div className="mc-card-link">
                                                    <a
                                                      className="abtn"
                                                      href="/come-acquistare/progetto-dolce-casa#form-dolcecasa"
                                                    >
                                                        { __('Scopri di più') }
                                                    </a>
                                                    <img
                                                      src="https://media.mondoconv.it/media/wysiwyg/2021-06/a-modo/desk-b/new-p/Tracciato 109.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </IfStore>
                    </div>
                </Popup>
            </>
        );
    }

    render() {
        const { stateMap } = this;
        const {
            navigationState: { name, isHiddenOnMobile = false },
            isCheckout
        } = this.props;

        if (!startMobile()) {
            // hide edit button on desktop
            stateMap[CUSTOMER_WISHLIST].edit = false;
            stateMap[CUSTOMER_WISHLIST].share = false;
            stateMap[CART_OVERLAY].edit = false;
        }

        return (
            <section block="Header" elem="Wrapper">
                <header
                  block="Header"
                  mods={ { name, isHiddenOnMobile, isCheckout } }
                  mix={ { block: 'FixedElement', elem: 'Top' } }
                  ref={ this.logoRef }
                >
                    <nav block="Header" elem="Nav">
                        { this.renderNavigationState() }
                        <IfStore storeCode="default">
                            { this.renderWelcomeUser() }
                        </IfStore>
                    </nav>
                    { this.renderCustomMenu() }
                </header>
                { this.renderBookPopups() }
                <OfflineNotice />
            </section>
        );
    }
}

export default HeaderComponent;
