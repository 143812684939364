import { Field } from 'Util/Query';

/** @namespace Pwa/Query/RebuildCart/Query/RebuildCartQuery */
export class RebuildCartQuery {
    getMutation(cartData, quoteId) {
        const mutation = new Field('addSimpleProductsToCart')
            .addArgument(
                'input',
                'AddSimpleProductsToCartInput',
                this._getAddSimpleProductsToCartInput(cartData, quoteId)
            )
            .addField(new Field('cart').addField('id'));

        return mutation;
    }

    _getAddSimpleProductsToCartInput(cartData, quoteId) {
        const cartItems = [];
        cartData.forEach((item) => {
            const cartItem = {
                data: {
                    sku: item.sku,
                    quantity: item.quantity
                }
            };

            cartItems.push(cartItem);
        });

        return {
            cart_id: quoteId,
            cart_items: cartItems
        };
    }
}

export default new RebuildCartQuery();
