// TODO rename
export const BLACKFRIDAYPRODUCT_ACTION_TYPE = 'BLACKFRIDAYPRODUCT_ACTION_TYPE';
export const BLACKFRIDAY_LOAD_PROMO = 'BLACKFRIDAY_LOAD_PROMO';

// TODO rename
/** @namespace Pwa/Store/BlackFridayCounterPromo/Action/loadBlackFridayCounterPromo */
export const loadBlackFridayCounterPromo = (payload) => ({
    type: BLACKFRIDAY_LOAD_PROMO,
    payload
});
