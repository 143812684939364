import { differenceInSeconds } from 'date-fns';

export const ONE_DAY = 86400;
export const ONE_HOUR = 3600;
export const ONE_MINUTE = 60;
export const DELTA_TIME = 10;

export function countdown(deadline, callback) {
    return setInterval(() => {
        const currentTime = new Date().getTime();
        const deadlineTime = new Date(deadline).getTime() + DELTA_TIME;

        const diffInSeconds = differenceInSeconds(deadlineTime, currentTime);

        if (diffInSeconds <= 1) {
            callback({
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            });
        } else {
            const days = Math.floor(parseFloat(diffInSeconds) / parseFloat(ONE_DAY));
            const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR);
            const minutes = Math.floor(
                (diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE
            );
            const seconds = diffInSeconds - days * ONE_DAY - hours * ONE_HOUR - minutes * ONE_MINUTE;

            callback({
                days,
                hours,
                minutes,
                seconds
            });
        }
    });
}
