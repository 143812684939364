import { getWarehouse } from 'Util/availability';

import { LOAD_WAREHOUSE, LOADING } from './Warehouse.action';

/** @namespace Pwa/Store/Warehouse/Reducer/getInitialState */
export const getInitialState = () => ({
    warehouse: getWarehouse(),
    isLoading: false
});

/** @namespace Pwa/Store/Warehouse/Reducer/WarehouseReducer */
export const WarehouseReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case LOAD_WAREHOUSE:
        const { payload } = action;

        return {
            ...state,
            warehouse: payload
        };
    case LOADING:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default WarehouseReducer;
