/* eslint-disable react/no-unused-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import Popup from 'Component/Popup';
import { ChildrenType } from 'Type/Common';

import './BannerModal.style';

/** @namespace Pwa/Component/BannerModal/Component/BannerModalComponent */
export class BannerModalComponent extends PureComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        banner: PropTypes.string.isRequired,
        type: PropTypes.string,
        children: ChildrenType.isRequired,
        showPopup: PropTypes.func.isRequired
    };

    static defaultProps = {
        type: 'default'
    };

    renderBanner() {
        const {
            id, banner, type, showPopup
        } = this.props;

        return (
            <div
              key={ id }
              block="BannerModal"
              elem="Wrapper"
              mods={ { type } }
              role="button"
              tabIndex="0"
              onClick={ showPopup }
              onKeyDown={ showPopup }

            >
                <Image
                  src={ banner }
                  mix={ {
                      block: 'BannerModal',
                      elem: 'Banner'
                  } }
                  ratio="custom"
                  alt="Banner"
                  onClick
                />
            </div>
        );
    }

    renderPopup() {
        const { id, children } = this.props;
        return (
            <Popup
              id={ id }
              mix={ {
                  block: 'BannerModal',
                  elem: 'Popup'
              } }
              clickOutside
            >
                { children }
            </Popup>
        );
    }

    render() {
        return (
            <div block="BannerModal">
                { this.renderBanner() }
                { this.renderPopup() }
            </div>
        );
    }
}

export default BannerModalComponent;
