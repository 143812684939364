/* eslint-disable max-lines */
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    POPUP_COOKIE_NAME, POPUP_DC_WHITELIST,
    POPUP_TOP_DOLCE_CASA,
    POPUP_TOP_TAVOLI_E_SEDIE, PROMO_BLOCK_NAME, PROMO_TOP_BLOCK_NAME
} from 'Component/PromoPopup/PromoPopup.config';
import CmsBlockQuery from 'Query/CmsBlock.query';
import { activateTopNav, deactivateTopNav } from 'Store/Promo/Promo.action';
import { shouldShowTopbar } from 'Util/PromoPopupSwitch';
import { fetchQuery } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';

import PromoPopup from './PromoPopup.component';

/** @namespace Pwa/Component/PromoPopup/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
});

/** @namespace Pwa/Component/PromoPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    activateTopNav: () => dispatch(activateTopNav()),
    deactivateTopNav: () => dispatch(deactivateTopNav())
});

/** @namespace Pwa/Component/PromoPopup/Container/PromoPopupContainer */
export class PromoPopupContainer extends DataContainer {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        routerProps: PropTypes.any,
        deactivateTopNav: PropTypes.func.isRequired,
        activateTopNav: PropTypes.func.isRequired
    };

    state = {
        popupBlock: {},
        topBlock: {},
        topBlockDolceCasa: {},
        topBlockTavoli: {}
    };

    containerFunctions = {
        hidePopup: this.hidePopup
    };

    containerProps = () => ({
        popupContent: this._getPopupContent(),
        topContent: this._getTopContent(),
        topBlockDolceCasaContent: this._getTopBlockDolceCasa(),
        topBlockTavoli: this._getTopBlockTavoli(),
        isDCWhiteList: this._getDCWhiteList(),
        isTeSWhiteList: this._getTeSWhiteList()
    });

    componentDidMount() {
        this._getBlocks();
        document.addEventListener('click', this.hidePopup);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.hidePopup);
    }

    componentDidUpdate(_prevProps, _prevState) {
        const { routerProps, activateTopNav, deactivateTopNav } = this.props;
        const { topBlock: { content } = {}, topBlockDolceCasa, topBlockTavoli } = this.state;

        if (!shouldShowTopbar(routerProps) || !(content || (topBlockDolceCasa?.content
            && this._getDCWhiteList())
            || (topBlockTavoli?.content && this._getTeSWhiteList()))) {
            deactivateTopNav();
        } else {
            activateTopNav();
        }
    }

    _getDCWhiteList() {
        const { routerProps: { location: { pathname } } } = this.props;
        const { whiteDCUrl } = this.state;

        return whiteDCUrl && whiteDCUrl.indexOf(pathname) > -1;
    }

    _getTeSWhiteList() {
        const { routerProps: { location: { pathname } } } = this.props;
        return pathname?.indexOf(__('/tavoli-e-sedie')) > -1;
    }

    _getPopupContent() {
        const { popupBlock } = this.state;
        return popupBlock?.content || '';
    }

    _getTopContent() {
        const { topBlock } = this.state;
        const { routerProps } = this.props;
        if (!shouldShowTopbar(routerProps)) {
            return '';
        }

        return topBlock?.content || '';
    }

    _getTopBlockDolceCasa() {
        const { topBlockDolceCasa } = this.state;
        const { routerProps } = this.props;
        if (!shouldShowTopbar(routerProps)) {
            return '';
        }

        return topBlockDolceCasa?.content || '';
    }

    _getTopBlockTavoli() {
        const { topBlockTavoli } = this.state;
        const { routerProps } = this.props;
        if (!shouldShowTopbar(routerProps)) {
            return '';
        }

        return topBlockTavoli?.content || '';
    }

    _getBlocks() {
        fetchQuery(CmsBlockQuery.getQuery({ identifiers: [PROMO_BLOCK_NAME] }))
            .then(
                /** @namespace Pwa/Component/PromoPopup/Container/fetchQuery/then */
                ({ cmsBlocks: { items } }) => {
                    if (!items.length) {
                        return;
                    }

                    this.setState({
                        ...this.state,
                        popupBlock: items[0]
                    });
                }
            );

        fetchQuery(CmsBlockQuery.getQuery({ identifiers: [PROMO_TOP_BLOCK_NAME] }))
            .then(
                /** @namespace Pwa/Component/PromoPopup/Container/fetchQuery/then */
                ({ cmsBlocks: { items } }) => {
                    if (!items.length) {
                        return;
                    }

                    this.setState({
                        ...this.state,
                        topBlock: items[0]
                    });
                }
            );

        fetchQuery(CmsBlockQuery.getQuery({ identifiers: [POPUP_TOP_DOLCE_CASA] }))
            .then(
                /** @namespace Pwa/Component/PromoPopup/Container/fetchQuery/then */
                ({ cmsBlocks: { items } }) => {
                    if (!items.length) {
                        return;
                    }

                    this.setState({
                        ...this.state,
                        topBlockDolceCasa: items[0]
                    });
                }
            );

        fetchQuery(CmsBlockQuery.getQuery({ identifiers: [POPUP_TOP_TAVOLI_E_SEDIE] }))
            .then(
                /** @namespace Pwa/Component/PromoPopup/Container/fetchQuery/then */
                ({ cmsBlocks: { items } }) => {
                    if (!items.length) {
                        return;
                    }

                    this.setState({
                        ...this.state,
                        topBlockTavoli: items[0]
                    });
                }
            );

        fetchQuery(CmsBlockQuery.getQuery({ identifiers: [POPUP_DC_WHITELIST] }))
            .then(
                /** @namespace Pwa/Component/PromoPopup/Container/fetchQuery/then */
                ({ cmsBlocks: { items } }) => {
                    if (!items.length) {
                        return;
                    }
                    const string = items[0]?.content || '';
                    const array = string.split(',');

                    this.setState({
                        ...this.state,
                        whiteDCUrl: array
                    });
                }
            );
    }

    hidePopup = () => {
        const { popupBlock } = this.state;
        if (!popupBlock || !popupBlock.content) {
            return;
        }

        Cookies.set(POPUP_COOKIE_NAME, popupBlock.content);
        this.setState({ popupBlock: 'null' });
    };

    render() {
        const { popupBlock } = this.state;
        const cookie = Cookies.get(POPUP_COOKIE_NAME);

        if ((!this._getPopupContent())
        && (!this._getTopContent()) && (!this._getTopBlockDolceCasa())
        && (!this._getTopBlockTavoli())) {
            document.documentElement.style.setProperty('--header-promo-popup-height', '0px');
            return null;
        }

        document.documentElement.style.setProperty('--header-promo-popup-height', '35px');

        const content = this._getPopupContent();
        const isPopupShown = popupBlock && content && cookie !== content;

        return (
            <PromoPopup
              isPopupShown={ isPopupShown }
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoPopupContainer);
