import AlgoliaInstantSearchContainer from 'Component/AlgoliaInstantSearch';
import {
    SearchField as SourceSearchField,
    SearchOverlay
} from 'SourceComponent/SearchField/SearchField.component';

import './SearchField.override.style';

export {
    SearchOverlay
};

/** @namespace Pwa/Component/SearchField/Component/SearchFieldComponent */
export class SearchFieldComponent extends SourceSearchField {
    renderDesktopContent() {
        const { device } = this.props;
        // const { showSearch } = this.state;

        if (device.isMobile) {
            return null;
        }

        return (
            <AlgoliaInstantSearchContainer />
        );
    }

    renderMobileContent() {
        const { device } = this.props;
        // const { showSearch } = this.state;

        if (!device.isMobile) {
            return null;
        }

        return (
            <AlgoliaInstantSearchContainer />
        );
    }
}

export default SearchFieldComponent;
