import {
    NewVersionPopup as SourceNewVersionPopup
} from 'SourceComponent/NewVersionPopup/NewVersionPopup.component';

import './NewVersionPopup.override.style';

/** @namespace Pwa/Component/NewVersionPopup/Component/NewVersionPopupComponent */
export class NewVersionPopupComponent extends SourceNewVersionPopup {
    // TODO implement logic
}

export default NewVersionPopupComponent;
