import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    DEFAULT_STATE,
    mapDispatchToProps,
    mapStateToProps,
    NavigationAbstractContainer as SourceNavigationAbstractContainer
} from 'SourceComponent/NavigationAbstract/NavigationAbstract.container';

export const DISABLE_OVERLAY_HIDE_ON_DESKTOP = [
    'ORDER_BY_OVERLAY'
];

export {
    DEFAULT_STATE,
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Pwa/Component/NavigationAbstract/Container/NavigationAbstractContainer */
export class NavigationAbstractContainer extends SourceNavigationAbstractContainer {
    static propTypes ={
        activeOverlay: PropTypes.string.isRequired
    };

    handleDesktopRouteChange() {
        const {
            hideActiveOverlay,
            setNavigationState,
            activeOverlay
        } = this.props;

        setNavigationState(this.routeMap['/']);
        if (!DISABLE_OVERLAY_HIDE_ON_DESKTOP.includes(activeOverlay)) {
            hideActiveOverlay();
        }

        return {};
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationAbstractContainer);
