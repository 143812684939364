import PropTypes from 'prop-types';

import {
    RenderWhenVisible as SourceRenderWhenVisible
} from 'SourceComponent/RenderWhenVisible/RenderWhenVisible.component';

/** @namespace Pwa/Component/RenderWhenVisible/Component/RenderWhenVisibleComponent */
export class RenderWhenVisibleComponent extends SourceRenderWhenVisible {
    static propTypes = {
        ...SourceRenderWhenVisible.propTypes,
        placeHolderSize: PropTypes.shape({
            width: PropTypes.string,
            height: PropTypes.string
        })
    };

    static defaultProps = {
        ...SourceRenderWhenVisible.defaultProps,
        placeHolderSize: {
            width: '',
            height: ''
        }
    };

    renderFallback() {
        const { placeHolderSize, placeHolderSize: { width, height } } = this.props;

        if (!width && !height) {
            return super.renderFallback();
        }

        return (
            <div style={ placeHolderSize } />
        );
    }
}

export default RenderWhenVisibleComponent;
