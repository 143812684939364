// TODO update this import when renamed
import BrowserDatabase from 'Util/BrowserDatabase';

import {
    CHANGE_MCMENU_SCROLL_PAGE,
    CHANGE_MCMENU_TREE_ITEM,
    COLLECTIONS_MENU,
    GET_CONFIGURATOR_FLAG,
    GET_CONFIGURATOR_FLAG_CHAIR,
    GET_CONFIGURATOR_FLAG_SOFAS,
    LOAD_MCMENU_TREE,
    RESET_MCMENU_SCROLL_PAGE,
    RESET_MCMENU_TREE_ITEM
} from './McMenu.action';
import { MCMENU } from './McMenu.config';

export const mcMenuTree = BrowserDatabase.getItem(MCMENU) || {};

/** @namespace Pwa/Store/McMenu/Reducer/getInitialState */
export const getInitialState = () => ({
    // TODO set initial state
    menuTree: mcMenuTree,
    currentItem: null,
    flagConfigurator: false,
    collections: {},
    scrollInfo: {
        active: false,
        scroll: 0
    }
});

/** @namespace Pwa/Store/McMenu/Reducer/McMenuReducer */
export const McMenuReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
    case LOAD_MCMENU_TREE:
        const { payload } = action;

        return {
            ...state,
            menuTree: payload
        };

    case CHANGE_MCMENU_TREE_ITEM:
        const { path } = action;

        return {
            ...state,
            currentItem: path
        };

    case RESET_MCMENU_TREE_ITEM:

        return {
            ...state,
            currentItem: null
        };

    case GET_CONFIGURATOR_FLAG:
        const { flagConfigurator } = action;
        return {
            ...state,
            flagConfigurator
        };

        // case GET_BF_CONFIGURATOR_FLAG:
        //     const { flagBFConfigurator } = action;
        //     return {
        //         ...state,
        //         flagBFConfigurator
        //     };

    case CHANGE_MCMENU_SCROLL_PAGE:
        const { amount } = action;

        return {
            ...state,
            scrollInfo: {
                active: true,
                scroll: amount
            }
        };

    case RESET_MCMENU_SCROLL_PAGE:

        return {
            ...state,
            scrollInfo: {
                active: false,
                scroll: 0
            }
        };

    case COLLECTIONS_MENU:
        const { collections } = action;
        return {
            ...state,
            collections
        };

    case GET_CONFIGURATOR_FLAG_CHAIR:
        const { flagConfiguratorChair } = action;
        return {
            ...state,
            flagConfiguratorChair
        };

    case GET_CONFIGURATOR_FLAG_SOFAS:
        const { flagConfiguratorSofas } = action;
        return {
            ...state,
            flagConfiguratorSofas
        };

    default:
        return state;
    }
};

export default McMenuReducer;
