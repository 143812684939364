// TODO update this import when renamed
import { LOAD_STORELOCATOR_SHOPS } from './StoreLocator.action';

/** @namespace Pwa/Store/StoreLocator/Reducer/getInitialState */
export const getInitialState = () => ({
    shopsByRegions: []
});

/** @namespace Pwa/Store/StoreLocator/Reducer/StoreLocatorReducer */
export const StoreLocatorReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case LOAD_STORELOCATOR_SHOPS:
        const { shopsByRegions } = action;

        return {
            ...state,
            shopsByRegions
        };

    default:
        return state;
    }
};

export default StoreLocatorReducer;
