// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';
import Popup from 'Component/Popup';

import { TEMPI_DI_CONSEGNA_POPUP } from './TempiDiConsegnaPopup.config';

import './TempiDiConsegnaPopup.style';

/** @namespace Pwa/Component/TempiDiConsegnaPopup/Component/TempiDiConsegnaPopupComponent */
export class TempiDiConsegnaPopupComponent extends PureComponent {
    render() {
        return (
            <Popup
              id={ TEMPI_DI_CONSEGNA_POPUP }
              clickOutside
              title={ __('TempiDiConsegna.title') }
            >
                <CmsBlock identifier={ TEMPI_DI_CONSEGNA_POPUP } />
            </Popup>
        );
    }
}

export default TempiDiConsegnaPopupComponent;
