/* eslint-disable fp/no-let */
/* eslint-disable max-len */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { NEWSLETTER_CUSTOM_POPUP } from 'Component/NewsletterCustomPopup/NewsletterCustomPopup.config';
import Popup from 'Component/Popup';

import './NewsletterCustomPopup.style';

/** @namespace Pwa/Component/NewsletterCustomPopup/Component/NewsletterCustomPopupComponent */
export class NewsletterCustomPopupComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        const message = `${__('Thank you for completing the registration form.') } ${ __('If you are not already registered, you will receive a confirmation email that will allow you to register for the newsletter and stay updated on the discounts and offers dedicated to our products.')}`;

        /* if (window.location.pathname === '/trasporto-e-montaggio') {
            message = 'Ecco qui il tuo codice: TM0DI300122';
        }
        if (window.location.pathname === '/promozione-camerette-coupon') {
            message = 'Ecco qui il tuo codice: 250DI220422';
        }
        if (window.location.pathname === '/promozione-camere-coupon') {
            message = 'Ecco qui il tuo codice: 250DI290422';
        }
        if (window.location.pathname === '/lp-mcday2022') {
            message = 'Complimenti, tu sì che hai fiuto per gli affari! Tieni d’occhio la tua email: presto riceverai novità';
        } */

        return (
            <Popup
              id={ NEWSLETTER_CUSTOM_POPUP }
              clickOutside
              title={ __('Legal notices') }
            >
                <div block="NewsletterCustomPopup">
                    <h4>
                        { __('Notice') }
                    </h4>
                    <div block="NewsletterCustomPopup" elem="body">
                        <i block="NewsletterCustomPopup" elem="icon" className="fa fa-check" aria-hidden="true" />
                        <div block="NewsletterCustomPopup" elem="text">
                            { message }
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}

export default NewsletterCustomPopupComponent;
