// TODO rename
export const MCREALTIMEPROMO_ACTION_TYPE = 'MCREALTIMEPROMO_ACTION_TYPE';
export const MCREALTIMEPROMO_LOAD_PROMO = 'MCREALTIMEPROMO_LOAD_PROMO';

// TODO rename
/** @namespace Pwa/Store/McRealTimePromo/Action/loadPromo */
export const loadPromo = (payload) => ({
    type: MCREALTIMEPROMO_LOAD_PROMO,
    payload
});
