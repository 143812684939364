/* eslint-disable no-param-reassign */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-else-return */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
import algoliasearch from 'algoliasearch/lite';
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';

import algoliaConfigPropTypes from 'Component/AlgoliaInstantSearch/algoliaConfigPropTypes';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';

import AlgoliaInstantSearch from './AlgoliaInstantSearch.component';
import { ALGOLIA_SEARCH_OVERLAY } from './AlgoliaInstantSearch.config';

/** @namespace Pwa/Component/AlgoliaInstantSearch/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    algoliaConfig: state.ConfigReducer.algolia,
    activeOverlay: state.OverlayReducer.activeOverlay
});

/** @namespace Pwa/Component/AlgoliaInstantSearch/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({ // eslint-disable-line no-unused-vars
    hideOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Pwa/Component/AlgoliaInstantSearch/Container/AlgoliaInstantSearchContainer */
export class AlgoliaInstantSearchContainer extends PureComponent {
    static propTypes = {
        algoliaConfig: algoliaConfigPropTypes.isRequired,
        activeOverlay: PropTypes.string.isRequired,
        hideOverlay: PropTypes.func.isRequired
    };

    containerFunctions = {
        onSearchSubmit: (e) => {
            e.preventDefault();

            if (this.inputTextRef.current.value.length <= 2) {
                return;
            }
            const querySearch = encodeURI(this.inputTextRef.current.value);

            self.location.href = `/search/${querySearch}`;
        },

        closeSearch: () => {
            const { hideOverlay, activeOverlay } = this.props;
            if (activeOverlay === ALGOLIA_SEARCH_OVERLAY) {
                // this.inputTextRef.current.value = '';
                hideOverlay();
            }
        }
    };

    __construct() {
        this.inputTextRef = createRef();
        this.state = {
            isActive: false,
            canc: true
        };
    }

    componentDidMount() {
        const search = document.querySelector('.CustomSearchBar-Input');
        if (search) {
            search.onkeydown = (event) => {
                const key = event.keyCode || event.charCode;
                if ((key === 8 || key === 46) && ((search.value.length < 4)
            || (search.value.length - (search.selectionEnd - search.selectionStart) < 3))) {
                    this.setState({ canc: false });
                } else {
                    this.setState({ canc: true });
                }
            };
        }

        const reset = document.querySelector('.CustomSearchBar-Reset');
        if (reset) {
            reset.addEventListener('click', () => {
                this.setState({ canc: false });
            });
        }
    }

    getAlgoliaSearchClient(algoliaConfig) {
        const { appId, searchOnlyApiKey } = algoliaConfig;

        const algoliaClient = algoliasearch(appId, searchOnlyApiKey, {
            _useRequestCache: true
        });

        const { isActive, canc } = this.state;

        return {
            search: (requests) => {
                const shouldSearch = requests.some(({ params: { query } }) => (query.length > 2));
                requests[0].params.filters = 'visibility_search=1';
                setTimeout(() => {
                    this.setState({ isActive: shouldSearch });
                }, 100);

                if (isActive && canc) {
                    return algoliaClient.search(requests);
                }

                return Promise.resolve({
                    results: [{ hits: [] }]
                });
            },
            searchForFacetValues: algoliaClient.searchForFacetValues
        };
    }

    render() {
        const { algoliaConfig } = this.props;
        const { isActive } = this.state;
        if (undefined === algoliaConfig) {
            return '';
        }
        const searchClient = this.getAlgoliaSearchClient(algoliaConfig);

        return (
            <AlgoliaInstantSearch
              searchClient={ searchClient }
              algoliaConfig={ algoliaConfig }
              inputRef={ this.inputTextRef }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlgoliaInstantSearchContainer);
