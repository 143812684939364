import PropTypes from 'prop-types';

export const algoliaConfigPropTypes = PropTypes.shape({
    appId: PropTypes.string.isRequired,
    searchOnlyApiKey: PropTypes.string.isRequired,
    categoriesIndex: PropTypes.string.isRequired,
    pagesIndex: PropTypes.string.isRequired,
    productsIndex: PropTypes.string.isRequired
});

export default algoliaConfigPropTypes;
