export const SET_TOTAL_ITEMS_SUB_CATEGORY_FILTERED = 'SET_TOTAL_ITEMS_SUB_CATEGORY_FILTERED';
export const RESET_TOTAL_ITEMS_SUB_CATEGORY_FILTERED = 'RESET_TOTAL_ITEMS_SUB_CATEGORY_FILTERED';
export const SET_START_PAGE_SUB_CATEGORY_FILTERED = 'SET_START_PAGE_SUB_CATEGORY_FILTERED';
export const SET_END_PAGE_SUB_CATEGORY_FILTERED = 'SET_END_PAGE_SUB_CATEGORY_FILTERED';

/** @namespace Pwa/Store/TotalItemsSubCategoryFiltered/Action/setTotalItemsSubCategoryFiltered */
export const setTotalItemsSubCategoryFiltered = (number) => ({
    type: SET_TOTAL_ITEMS_SUB_CATEGORY_FILTERED,
    number
});

/** @namespace Pwa/Store/TotalItemsSubCategoryFiltered/Action/resetTotalItemsSubCategoryFiltered */
export const resetTotalItemsSubCategoryFiltered = () => ({
    type: RESET_TOTAL_ITEMS_SUB_CATEGORY_FILTERED
});

/** @namespace Pwa/Store/TotalItemsSubCategoryFiltered/Action/setStartPageSubCategoryFiltered */
export const setStartPageSubCategoryFiltered = (number) => ({
    type: SET_START_PAGE_SUB_CATEGORY_FILTERED,
    number
});

/** @namespace Pwa/Store/TotalItemsSubCategoryFiltered/Action/setEndPageSubCategoryFiltered */
export const setEndPageSubCategoryFiltered = (number) => ({
    type: SET_END_PAGE_SUB_CATEGORY_FILTERED,
    number
});
