import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Highlight } from 'react-instantsearch-dom';

import './SearchResultProduct.style.scss';

/** @namespace Pwa/Component/AlgoliaInstantSearch/SearchResultProduct/Component/SearchResultProductComponent */
export class SearchResultProductComponent extends PureComponent {
    static propTypes = {
        hit: PropTypes.shape({
            objectID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            thumbnail_url: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
            categories: PropTypes.shape({
                level0: PropTypes.arrayOf(PropTypes.string)
            }),
            price: PropTypes.shape({
                EUR: PropTypes.shape({
                    default_formated: PropTypes.string.isRequired,
                    default_original_formated: PropTypes.string.isRequired
                })
            }),
            categories_without_path: PropTypes.arrayOf(PropTypes.string)
        }).isRequired
    };

    render() {
        const { hit } = this.props;

        const categories = hit.categories.level0 || [];
        const categoriesText = categories.length ? `${__('in')} ${categories.join(', ')}` : '';
        const { name } = hit;
        const { url: finalUrl } = hit;

        return (
            <a block="SearchResultProduct" href={ finalUrl }>
                <div block="SearchResultProduct" elem="left">
                    <div block="SearchResultProduct" elem="image">
                        <img src={ hit.thumbnail_url } alt={ name } />
                    </div>
                </div>
                <div block="SearchResultProduct" elem="right">
                    <div block="SearchResultProduct" elem="name"><Highlight attribute="name" hit={ hit } /></div>
                    <div block="SearchResultProduct" elem="categories">{ categoriesText }</div>
                    { hit.price.EUR && (
                        <div block="SearchResultProduct" elem="price">
                            <div block="SearchResultProduct" elem="sellPrice">{ hit.price.EUR.default_formated }</div>
                            { hit.price.EUR.default_original_formated && (
                                <div
                                  block="SearchResultProduct"
                                  elem="originalPrice"
                                >
                                    { hit.price.EUR.default_original_formated }
                                </div>
                            ) }
                        </div>
                    ) }
                </div>
            </a>
        );
    }
}

export default SearchResultProductComponent;
