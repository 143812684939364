export const METODO_CONSEGNA_CUSTOM_POPUP = 'METODO_CONSEGNA_CUSTOM_POPUP';
export const METODO_CONSEGNA_CUSTOM_POPUP_NEW = 'METODO_CONSEGNA_CUSTOM_POPUP_NEW';
export const GARANZIA_CUSTOM_POPUP = 'GARANZIA_CUSTOM_POPUP';
export const SERVIZI_CUSTOM_POPUP = 'SERVIZI_CUSTOM_POPUP';
export const CATAS_CUSTOM_POPUP = 'CATAS_CUSTOM_POPUP';
export const COSMOB_CUSTOM_POPUP = 'COSMOB_CUSTOM_POPUP';
export const VARIANT_POPUP = 'VARIANT_POPUP';
export const COSMOB_MATERASSI_POPUP = 'COSMOB_MATERASSI_POPUP';
export const CATAS_MATERASSI_POPUP = 'CATAS_MATERASSI_POPUP';
export const FINANCING_POPUP = 'FINANCING_POPUP';
export const BOOK_POPUP_KITCHEN = 'BOOK_POPUP_KITCHEN';
export const BOOK_POPUP_BEDROOM = 'BOOK_POPUP_BEDROOM';
export const GARANZIA_5 = 5;
export const GARANZIA_10 = 10;
export const GARANZIA_15 = 15;

export const DEFAULT_MAX_PRODUCTS = 99;
