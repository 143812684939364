import IfStore from 'Component/IfStore';
import {
    Loader as SourceLoader
} from 'SourceComponent/Loader/Loader.component';

import { ReactComponent as LoadingLogoIT } from './assets/icon-loading-it.svg';
import { ReactComponent as LoadingLogo } from './assets/icon-loading.svg';

import './Loader.override.style';

/** @namespace Pwa/Component/Loader/Component/LoaderComponent */
export class LoaderComponent extends SourceLoader {
    render() {
        const { isLoading } = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="McLoader" elem="Main">
                <IfStore storeCode="default">
                    <LoadingLogoIT />
                </IfStore>
                <IfStore storeCode="spain">
                    <LoadingLogo />
                </IfStore>

                <div block="McLoader" elem="Flag">
                    <div block="McLoader" elem="GreenCircle" />
                    <div block="McLoader" elem="WhiteCircle" />
                    <div block="McLoader" elem="RedCircle" />
                </div>
            </div>
        );
    }
}

export default LoaderComponent;
