import {
    formConfig as sourceFormConfig,
    isNotEmpty,
    MIN_PASSWORD_LENGTH,
    validateEmail,
    validateEmails,
    validatePasswordMatch
} from 'SourceComponent/Form/Form.config';

export {
    MIN_PASSWORD_LENGTH,
    validateEmail,
    validateEmails,
    isNotEmpty,
    validatePasswordMatch
};

/** @namespace Pwa/Component/Form/Config/validateTelephone */
export const validateTelephone = ({ value }) => value.match(/^[0-9.-]{7,}\s*$/);

/** @namespace Pwa/Component/Form/Config/validateCheckbox */
export const validateCheckbox = ({ checked }) => checked;

/** @namespace Pwa/Component/Form/Config/validatePassword */
export const validatePassword = ({ value }) => value
    .match(new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@.,;:()+=£|<>$%^&*-]).{8,}$'));

// TODO: implement formConfig
/** @namespace Pwa/Component/Form/Config/formConfig */
export const formConfig = () => ({
    ...sourceFormConfig(),
    checked: {
        validate: validateCheckbox,
        message: __('Required')
    },
    telephone: {
        validate: validateTelephone,
        message: __('Phone number is invalid!')
    },
    password: {
        validate: validatePassword,
        message: __('Forgot Password rules')
    }
});

export default formConfig();
