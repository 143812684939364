/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import {
    Configure, Hits, Index, InstantSearch
} from 'react-instantsearch-dom';

import algoliaConfigPropTypes from 'Component/AlgoliaInstantSearch/algoliaConfigPropTypes';
import AlgoliaResults from 'Component/AlgoliaInstantSearch/AlgoliaResults.component';
import SearchResultCategoryComponent from 'Component/AlgoliaInstantSearch/SearchResultCategory.component';
import SearchResultPageComponent from 'Component/AlgoliaInstantSearch/SearchResultPage.component';
import ClickOutside from 'Component/ClickOutside';
import CustomSearchBar from 'Component/CustomSearchBar';
import Overlay from 'Component/Overlay';

import { ALGOLIA_SEARCH_CONTAINER, ALGOLIA_SEARCH_OVERLAY } from './AlgoliaInstantSearch.config';
import SearchResultProductComponent from './SearchResultProduct.component';

import './AlgoliaInstantSearch.style';

/** @namespace Pwa/Component/AlgoliaInstantSearch/Component/AlgoliaInstantSearchComponent */
export class AlgoliaInstantSearchComponent extends PureComponent {
    static propTypes = {
        algoliaConfig: algoliaConfigPropTypes.isRequired,
        onSearchSubmit: PropTypes.func.isRequired,
        closeSearch: PropTypes.func.isRequired,
        inputRef: PropTypes.object.isRequired,
        searchClient: PropTypes.shape({
            search: PropTypes.func.isRequired,
            searchForFacetValues: PropTypes.bool.isRequired
        }).isRequired
    };

    render() {
        const {
            onSearchSubmit, inputRef, algoliaConfig, searchClient, closeSearch
        } = this.props;
        const {
            productsIndex, categoriesIndex, pagesIndex
        } = algoliaConfig;
        const searchBoxTranslations = {
            placeholder: __('Search a product...')
        };

        return (
            <ClickOutside
              onClick={ () => closeSearch() }
              key={ ALGOLIA_SEARCH_CONTAINER }
            >
            <div block="AlgoliaInstantSearch">
                <InstantSearch searchClient={ searchClient } indexName={ productsIndex }>
                    <CustomSearchBar inputRef={ inputRef } />

                    <Overlay
                      id={ ALGOLIA_SEARCH_OVERLAY }
                      mix={ { block: 'AlgoliaResults' } }
                    >
                        <div block="AlgoliaResults" elem="leftCol">
                            <div block="AlgoliaResults" elem="categoriesResults">
                                <div block="AlgoliaResults" elem="heading">{ __('Categories') }</div>

                                <Index indexName={ categoriesIndex }>
                                    <Configure hitsPerPage={ 6 } />
                                    <AlgoliaResults>
                                        <Hits hitComponent={ SearchResultCategoryComponent } />
                                    </AlgoliaResults>
                                </Index>
                            </div>

                            <div block="AlgoliaResults" elem="pagesResults">
                                <div block="AlgoliaResults" elem="heading">{ __('Pages') }</div>

                                <Index indexName={ pagesIndex }>
                                    <Configure hitsPerPage={ 6 } />
                                    <AlgoliaResults>
                                        <Hits hitComponent={ SearchResultPageComponent } />
                                    </AlgoliaResults>
                                </Index>
                            </div>
                        </div>

                        <div block="AlgoliaResults" elem="rightCol">
                            <div block="AlgoliaResults" elem="productsResults">
                                <div block="AlgoliaResults" elem="heading">{ __('Products') }</div>

                                <Index indexName={ productsIndex }>
                                    <Configure hitsPerPage={ 6 } />
                                    <AlgoliaResults>
                                        <Hits hitComponent={ SearchResultProductComponent } />
                                        <button
                                          block="AlgoliaResults"
                                          elem="showAll"
                                          onClick={ onSearchSubmit }
                                        >
                                            { __('See all resulting products') }
                                        </button>
                                    </AlgoliaResults>
                                </Index>
                            </div>
                        </div>
                    </Overlay>
                </InstantSearch>
            </div>
            </ClickOutside>
        );
    }
}

export default AlgoliaInstantSearchComponent;
