export const SELECT_SUB_CATEGORY = 'SELECT_SUB_CATEGORY';
export const RESET_SUB_CATEGORY = 'RESET_SUB_CATEGORY';

/** @namespace Pwa/Store/SubCategoryFilterTags/Action/selectSubCategory */
export const selectSubCategory = (categoryName) => ({
    type: SELECT_SUB_CATEGORY,
    categoryName
});

/** @namespace Pwa/Store/SubCategoryFilterTags/Action/resetSubCategory */
export const resetSubCategory = () => ({
    type: RESET_SUB_CATEGORY
});
