import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    resetMcMenuPageScroll,
    setMcMenuPageScroll
} from 'Store/McMenu/McMenu.action';
import { toggleScroll } from 'Util/Browser';

/** @namespace Pwa/Component/McMenuScrollFreeze/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currentScroll: state.McMenuReducer.scrollInfo
});

/** @namespace Pwa/Component/McMenuScrollFreeze/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setScrollPage: (amount) => dispatch(setMcMenuPageScroll(amount)),
    resetScrollPage: () => dispatch(resetMcMenuPageScroll())
});

/** @namespace Pwa/Component/McMenuScrollFreeze/Container/McMenuScrollFreezeContainer */
export class McMenuScrollFreezeContainer extends PureComponent {
    static propTypes = {
        setScrollPage: PropTypes.func.isRequired,
        resetScrollPage: PropTypes.func.isRequired,
        currentScroll: PropTypes.shape({
            active: PropTypes.bool,
            scroll: PropTypes.number
        })
    };

    static defaultProps = {
        currentScroll: {
            active: false,
            scroll: 0
        }
    };

    freezeScroll() {
        const { currentScroll: { scroll: currentScrollAmount }, setScrollPage } = this.props;
        const scrollAmount = currentScrollAmount || window.pageYOffset || document.body.scrollTop;
        setScrollPage(scrollAmount);
        toggleScroll(false);
        document.body.style.marginTop = `${-scrollAmount}px`;
    }

    unfreezeScroll() {
        const { currentScroll: { scroll: currentScrollAmount }, resetScrollPage } = this.props;
        toggleScroll(true);
        document.body.style.marginTop = 0;
        window.scrollTo(0, currentScrollAmount);
        resetScrollPage();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(McMenuScrollFreezeContainer);
