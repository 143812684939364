export const INCREASE_LOADING = 'INCREASE_LOADING';
export const DECREASE_LOADING = 'DECREASE_LOADING';
export const RESET_LOADING = 'RESET_LOADING';

/** @namespace Pwa/Store/Configuratore/Action/increaseLoading */
export const increaseLoading = () => ({
    type: INCREASE_LOADING
});

/** @namespace Pwa/Store/Configuratore/Action/decreaseLoading */
export const decreaseLoading = () => ({
    type: DECREASE_LOADING
});

/** @namespace Pwa/Store/Configuratore/Action/resetLoading */
export const resetLoading = () => ({
    type: RESET_LOADING
});
