/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import Cookies from 'js-cookie';

import { isSignedIn } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';

export const GUEST_QUOTE_ID = 'guest_quote_id';
export const GUEST_QUOTE_ID_COOKIE_NAME = 'gqid';

/** @namespace Util/Token/setGuestQuoteId */
export const setGuestQuoteId = (token) => {
    Cookies.set(GUEST_QUOTE_ID_COOKIE_NAME, token, { expires: 365 });
    BrowserDatabase.setItem({
        token,
        isCustomerToken: isSignedIn()
    }, GUEST_QUOTE_ID);
};

/** @namespace Util/Token/getGuestQuoteId */
export const getGuestQuoteId = () => {
    const {
        token,
        isCustomerToken
    } = BrowserDatabase.getItem(GUEST_QUOTE_ID) || {};

    if (isCustomerToken && !isSignedIn()) {
        BrowserDatabase.deleteItem(GUEST_QUOTE_ID);
        return null;
    }

    Cookies.set(GUEST_QUOTE_ID_COOKIE_NAME, token, { expires: 365 });
    return token;
};

/** @namespace Util/Token/deleteGuestQuoteId */
export const deleteGuestQuoteId = () => {
    BrowserDatabase.deleteItem(GUEST_QUOTE_ID);
    Cookies.remove(GUEST_QUOTE_ID_COOKIE_NAME);
};

/** @namespace Util/Token/refreshCookie */
export const refreshCookie = () => {
    const { token } = BrowserDatabase.getItem(GUEST_QUOTE_ID) || '';
    const cookieValue = Cookies.get(GUEST_QUOTE_ID_COOKIE_NAME);
    if (token !== cookieValue) {
        Cookies.set(GUEST_QUOTE_ID_COOKIE_NAME, token, { expires: 365 });
    }
};
