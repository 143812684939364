// TODO update this import when renamed
import { HOMEPAGE_SLIDER_LOAD_PRODUCTS } from './HomepageSlider.action';

/** @namespace Pwa/Store/HomepageSlider/Reducer/getInitialState */
export const getInitialState = () => ({
    sliderProducts: []
});

/** @namespace Pwa/Store/HomepageSlider/Reducer/HomepageSliderReducer */
export const HomepageSliderReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case HOMEPAGE_SLIDER_LOAD_PRODUCTS:
        const { sliderProducts } = action;

        return {
            ...state,
            sliderProducts
        };

    default:
        return state;
    }
};

export default HomepageSliderReducer;
