import { connect } from 'react-redux';

import { LEGAL_NOTES_POPUP } from 'Component/LegalNotesPopup/LegalNotesPopup.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Footer/Footer.container';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { showPopup } from 'Store/Popup/Popup.action';

import Footer from './Footer.component';

/** @namespace Pwa/Component/Footer/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    navigationState: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState
});

/** @namespace Pwa/Component/Footer/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showPopup: (payload) => dispatch(showPopup(LEGAL_NOTES_POPUP, payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
