import {
    Logo as SourceLogo
} from 'SourceComponent/Logo/Logo.component';

import './Logo.override.style';

/** @namespace Pwa/Component/Logo/Component/LogoComponent */
export class LogoComponent extends SourceLogo {
    render() {
        const { storeCode } = this.props;

        if (!storeCode) {
            return null;
        }

        return (
            <div
              block="Image"
              mix={ { block: 'Logo' } }
            >
                <img src={ `/media/logo/stores/${storeCode}/logo.png` } alt="Logo" className="Image-Image" />
            </div>
        );
    }
}

export default LogoComponent;
