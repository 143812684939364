// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { McMenuType } from 'Type/McMenuType';

import McMenu from './McMenu.component';

/** @namespace Pwa/Component/McMenu/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    menuTree: state.McMenuReducer.menuTree
});

/** @namespace Pwa/Component/McMenu/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

/** @namespace Pwa/Component/McMenu/Container/McMenuContainer */
export class McMenuContainer extends PureComponent {
    static propTypes = {
        menuTree: McMenuType.isRequired
        // TODO: implement prop-types
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    render() {
        const { menuTree } = this.props;
        return (
            <McMenu
              { ...this.containerFunctions }
              menuTree={ menuTree }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(McMenuContainer);
