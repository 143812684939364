// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import McMenuItem from 'Component/McMenuItem';
import { McMenuType } from 'Type/McMenuType';

import './McMenu.style';

/** @namespace Pwa/Component/McMenu/Component/McMenuComponent */
export class McMenuComponent extends PureComponent {
    static propTypes = {
        menuTree: McMenuType.isRequired
    };

    render() {
        const { menuTree } = this.props;

        return (
            <ul block="McMenu">
                { menuTree.nodes && menuTree.nodes.map((item) => (
                    <McMenuItem key={ item.path } data={ item } />
                )) }
            </ul>
        );
    }
}

export default McMenuComponent;
