import {
    Notification as SourceNotification
} from 'SourceComponent/Notification/Notification.component';

import './Notification.override.style';

/** @namespace Pwa/Component/Notification/Component/NotificationComponent */
export class NotificationComponent extends SourceNotification {
    // TODO implement logic
}

export default NotificationComponent;
