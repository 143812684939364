import {
    Meta as SourceMeta
} from 'SourceComponent/Meta/Meta.component';

/** @namespace Pwa/Component/Meta/Component/MetaComponent */
export class MetaComponent extends SourceMeta {
    // TODO implement logic
}

export default MetaComponent;
