import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    McMenuScrollFreezeContainer
} from 'Component/McMenuScrollFreeze/McMenuScrollFreeze.container';
import { resetCurrentMcMenuItem } from 'Store/McMenu/McMenu.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { McMenuNodeType } from 'Type/McMenuType';

import McMenuDropdown from './McMenuDropdown.component';

/** @namespace Pwa/Component/McMenuDropdown/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    currentMenuItem: state.McMenuReducer.currentItem
});

/** @namespace Pwa/Component/McMenuDropdown/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    resetCurrentItem: () => dispatch(resetCurrentMcMenuItem()),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Pwa/Component/McMenuDropdown/Container/McMenuDropdownContainer */
export class McMenuDropdownContainer extends McMenuScrollFreezeContainer {
    static propTypes = {
        data: McMenuNodeType.isRequired,
        currentMenuItem: PropTypes.string,
        resetCurrentItem: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    static defaultProps = {
        currentMenuItem: null
    };

    containerFunctions = {
        onDropdownMenu: this.onDropdownMenu.bind(this),
        onHideOverlay: this.onHideOverlay.bind(this),
        onVisibleOverlay: this.onVisibleOverlay.bind(this),
        rewriteUrls: this.rewriteUrls.bind(this)
    };

    containerProps = () => {
        const { data } = this.props;
        const { current_submenu } = this.state;

        return {
            data,
            current_submenu
        };
    };

    rewriteUrls(url) {
        switch (url) {
        case '/offerte/angolo-delle-occasioni.html':
        case '/offerte/offerte/angolo-delle-occasioni.html':
            return '/angolo-delle-occasioni';
        default:
            return url;
        }
    }

    __construct(props) {
        super.__construct(props);

        this.state = {
            current_submenu: null
        };
    }

    checkCurrentMenu() {
        const { data: { path: actualMenuItem }, currentMenuItem } = this.props;

        return (actualMenuItem === currentMenuItem);
    }

    onDropdownMenu(path) {
        const { current_submenu } = this.state;
        const new_submenu = (path !== current_submenu) ? path : null;
        this.setState({ current_submenu: new_submenu });
    }

    onVisibleOverlay() {
        const { currentScroll: { active: scrollStatus } } = this.props;

        if (scrollStatus) {
            this.unfreezeScroll();
        }
    }

    onHideOverlay() {
        const { resetCurrentItem } = this.props;

        if (this.checkCurrentMenu()) {
            resetCurrentItem();
        }
    }

    render() {
        return (
            <McMenuDropdown
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(McMenuDropdownContainer);
