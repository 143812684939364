/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { connect } from 'react-redux';

import { ALGOLIA_SEARCH_OVERLAY } from 'Component/AlgoliaInstantSearch/AlgoliaInstantSearch.config';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';

import CustomSearchBar from './CustomSearchBar.component';

/** @namespace Pwa/Component/CustomSearchBar/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    activeOverlay: state.OverlayReducer.activeOverlay
});

/** @namespace Pwa/Component/CustomSearchBar/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showOverlay: () => dispatch(toggleOverlayByKey(ALGOLIA_SEARCH_OVERLAY)),
    hideOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Pwa/Component/CustomSearchBar/Container/CustomSearchBarContainer */
export class CustomSearchBarContainer extends PureComponent {
    static propTypes = {
        currentRefinement: PropTypes.string.isRequired,
        refine: PropTypes.func.isRequired,
        isActive: PropTypes.bool,
        inputRef: PropTypes.object.isRequired,
        activeOverlay: PropTypes.string.isRequired,
        showOverlay: PropTypes.func.isRequired,
        hideOverlay: PropTypes.func.isRequired
    };

    static defaultProps ={
        isActive: false
    };

    containerFunctions = {
        search: this.performSearch.bind(this),
        reset: this.resetSearch.bind(this),
        onSearchSubmit: this.onSearchSubmit.bind(this)
    };

    containerProps = () => {
        const { currentRefinement: value, inputRef } = this.props;

        return {
            value,
            inputRef
        };
    };

    performSearch(event) {
        const {
            refine, activeOverlay, hideOverlay, showOverlay
        } = this.props;

        refine(event.currentTarget.value);

        if (event.currentTarget.value.length <= 2) {
            hideOverlay();
        } else if (activeOverlay !== ALGOLIA_SEARCH_OVERLAY) {
            showOverlay();
        }
    }

    resetSearch() {
        const { refine, activeOverlay, hideOverlay } = this.props;
        if (activeOverlay === ALGOLIA_SEARCH_OVERLAY) {
            hideOverlay();
        }
        refine('');
    }

    onSearchSubmit(e) {
        const { currentRefinement } = this.props;
        e.preventDefault();

        if (currentRefinement.length <= 2) {
            return;
        }

        const querySearch = encodeURI(currentRefinement);
        self.location.href = `/search/${querySearch}`;
    }

    render() {
        return (
            <CustomSearchBar
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(connectSearchBox(CustomSearchBarContainer));
