// TODO update this import when renamed
import { LOAD_MC_AVAILABILITY_ACTION_PENDING_TYPE, LOAD_MC_AVAILABILITY_ACTION_TYPE } from './McAvailability.action';

/** @namespace Pwa/Store/McAvailability/Reducer/getInitialState */
export const getInitialState = () => ({
    mcAvailability: {}
});

/** @namespace Pwa/Store/McAvailability/Reducer/McAvailabilityReducer */
export const McAvailabilityReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case LOAD_MC_AVAILABILITY_ACTION_TYPE:
        const { mcAvailability } = action;

        return {
            ...state,
            mcAvailability
        };

    case LOAD_MC_AVAILABILITY_ACTION_PENDING_TYPE:
        return {
            ...state,
            mcAvailability: {}
        };

    default:
        return state;
    }
};

export default McAvailabilityReducer;
