import {
    NavigationTabs as SourceNavigationTabs
} from 'SourceComponent/NavigationTabs/NavigationTabs.component';

import './NavigationTabs.override.style';

/** @namespace Pwa/Component/NavigationTabs/Component/NavigationTabsComponent */
export class NavigationTabsComponent extends SourceNavigationTabs {
    // TODO implement logic
}

export default NavigationTabsComponent;
