/* eslint-disable react/no-danger */
import parse from 'html-react-parser';

import { FieldSelect as SourceFieldSelect } from 'SourceComponent/FieldSelect/FieldSelect.component';

/** @namespace Pwa/Component/FieldSelect/Component/FieldSelectComponent */
export class FieldSelectComponent extends SourceFieldSelect {
    renderOption = (option) => {
        const {
            id,
            label
        } = option;

        const {
            isSelectExpanded: isExpanded,
            handleSelectListOptionClick, isDisabled
        } = this.props;

        if (isDisabled) {
            return null;
        }

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ { isExpanded } }
              key={ id }
              /**
               * Added 'o' as querySelector does not work with
               * ids, that consist of numbers only
               */
              id={ `o${id}` }
              role="menuitem"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                { parse(label) }
            </li>
        );
    };

    renderNativeOption = (option) => {
        const {
            id,
            value,
            disabled,
            label
        } = option;

        return (
            <option
              key={ id }
              id={ id }
              value={ value }
              disabled={ disabled }
              dangerouslySetInnerHTML={ { __html: label } }
            >
                { /* { label } */ }
            </option>
        );
    };
}

export default FieldSelectComponent;
