import {
    RESET_TOTAL_ITEMS_SUB_CATEGORY_FILTERED,
    SET_END_PAGE_SUB_CATEGORY_FILTERED,
    SET_START_PAGE_SUB_CATEGORY_FILTERED,
    SET_TOTAL_ITEMS_SUB_CATEGORY_FILTERED
} from 'Store/TotalItemsSubCategoryFiltered/TotalItemsSubCategoryFiltered.action';

/** @namespace Pwa/Store/TotalItemsSubCategoryFiltered/Reducer/getInitialState */
export const getInitialState = () => ({
    isShowCategoryFilters: false,
    selectedFilters: {},
    selectedAttributeSets: undefined,
    selectedAttributeSetFilter: {}
});

/** @namespace Pwa/Store/TotalItemsSubCategoryFiltered/Reducer/TotalItemsSubCategoryFilteredReducer */
export const TotalItemsSubCategoryFilteredReducer = (
    state = getInitialState(),
    action
) => {
    const { type, number } = action;
    switch (type) {
    case SET_TOTAL_ITEMS_SUB_CATEGORY_FILTERED:
        return {
            ...state,
            totalItemsSubCategoryFiltered: number
        };

    case RESET_TOTAL_ITEMS_SUB_CATEGORY_FILTERED:
        return {
            ...state,
            totalItemsSubCategoryFiltered: 0
        };

    case SET_START_PAGE_SUB_CATEGORY_FILTERED:
        return {
            ...state,
            startPageSubCategoryFiltered: number
        };

    case SET_END_PAGE_SUB_CATEGORY_FILTERED:
        return {
            ...state,
            endPageSubCategoryFiltered: number
        };

    default:
        return {
            ...state
        };
    }
};

export default TotalItemsSubCategoryFilteredReducer;
