// TODO update this import when renamed
import { MCREALTIMEPROMO_LOAD_PROMO } from './McRealTimePromo.action';

/** @namespace Pwa/Store/McRealTimePromo/Reducer/getInitialState */
export const getInitialState = () => ({
    promo: {}
});

/** @namespace Pwa/Store/McRealTimePromo/Reducer/McRealTimePromoReducer */
export const McRealTimePromoReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case MCREALTIMEPROMO_LOAD_PROMO:
        const { payload } = action;

        return {
            ...state,
            promo: payload
        };

    default:
        return state;
    }
};

export default McRealTimePromoReducer;
