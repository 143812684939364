// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import TempiDiConsegnaPopup from './TempiDiConsegnaPopup.component';

/** @namespace Pwa/Component/TempiDiConsegnaPopup/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({

});

/** @namespace Pwa/Component/TempiDiConsegnaPopup/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({

});

/** @namespace Pwa/Component/TempiDiConsegnaPopup/Container/TempiDiConsegnaPopupContainer */
export class TempiDiConsegnaPopupContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps = () => {
        // isDisabled: this._getIsDisabled()
    };

    render() {
        return (
            <TempiDiConsegnaPopup
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TempiDiConsegnaPopupContainer);
