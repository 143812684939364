import {
    Menu as SourceMenu
} from 'SourceComponent/Menu/Menu.component';

import './Menu.override.style';

/** @namespace Pwa/Component/Menu/Component/MenuComponent */
export class MenuComponent extends SourceMenu {
    // TODO implement logic
}

export default MenuComponent;
