import {
    CartQuery as SourceCartQuery
} from 'SourceQuery/Cart.query';

/** @namespace Pwa/Query/Cart/Query/CartQuery */
export class CartQuery extends SourceCartQuery {
    _getCartItemFields() {
        return [
            'qty',
            'sku',
            'price',
            'item_id',
            'row_total',
            'row_total_incl_tax',
            'tax_amount',
            'tax_percent',
            'discount_amount',
            'discount_percent',
            'availability_from',
            this._getCustomizableOptionsFields(),
            this._getDownloadableLinksField(),
            this._getBundleOptionsField(),
            this._getProductField()
        ];
    }
}

export default new CartQuery();
