// TODO update this import when renamed
import { MCTECHSPECS_ACTION_TYPE } from './McTechSpecs.action';

/** @namespace Pwa/Store/McTechSpecs/Reducer/getInitialState */
export const getInitialState = () => ({
    mcTechSpecs: {}
});

/** @namespace Pwa/Store/McTechSpecs/Reducer/McTechSpecsReducer */
export const McTechSpecsReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case MCTECHSPECS_ACTION_TYPE:
        const { mcTechSpecs } = action;

        return {
            ...state,
            mcTechSpecs
        };

    default:
        return state;
    }
};

export default McTechSpecsReducer;
