import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    LogoContainer as SourceLogoContainer
} from 'SourceComponent/Logo/Logo.container';

/** @namespace Pwa/Component/Logo/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    storeCode: state.ConfigReducer.code
});

/** @namespace Pwa/Component/Logo/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({

});

/** @namespace Pwa/Component/Logo/Container/LogoContainer */
export class LogoContainer extends SourceLogoContainer {
    static propTypes = {
        storeCode: PropTypes.string.isRequired
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoContainer);
