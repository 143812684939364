import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MenuContainer as SourceMenuContainer
} from 'SourceComponent/Menu/Menu.container';

/** @namespace Pwa/Component/Menu/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/Menu/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Component/Menu/Container/MenuContainer */
export class MenuContainer extends SourceMenuContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
