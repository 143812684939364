import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import TempiDiConsegnaPopup from 'Component/TempiDiConsegnaPopup';

import './TempiDiConsegnaBanner.style.scss';

/** @namespace Pwa/Component/TempiDiConsegnaBanner/Component/TempiDiConsegnaBannerComponent */
export class TempiDiConsegnaBannerComponent extends PureComponent {
    static propTypes = {
        fnMostraPopup: PropTypes.func.isRequired
    };

    state = {
        messageText: 'Tempistiche di consegna'
    };

    render() {
        const { fnMostraPopup } = this.props;
        const { messageText } = this.state;

        return (
            <>
                <TempiDiConsegnaPopup />
                <div className="bannerContainer">
                <i className="fa fa-exclamation-triangle" aria-hidden="true" />
                <button
                  type="button"
                  className="openTdcPopup"
                  onClick={ fnMostraPopup }
                >
                   { messageText }
                </button>
                <i className="fa fa-chevron-up tracciato" aria-hidden="true" onClick={ fnMostraPopup } />
                </div>
            </>
        );
    }
}

export default TempiDiConsegnaBannerComponent;
