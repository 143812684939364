/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { Link as RouterLink } from 'react-router-dom';
import { stringify } from 'rebem-classname';

import { Link as SourceLinkComponent } from 'SourceComponent/Link/Link.component';

/** @namespace Pwa/Component/Link/Component/LinkComponent */
export class LinkComponent extends SourceLinkComponent {
    render() {
        const {
            className, bemProps, children, to, ...props
        } = this.props;

        if (!to) {
            return (
                <div { ...props } { ...bemProps }>
                    { children }
                </div>
            );
        }

        if (/^#/.test(to)) {
            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <a
                  { ...props }
                  { ...bemProps }
                  onClick={ this.scrollToElement }
                  href={ to }
                >
                    { children }
                </a>
            );
        }

        const classNameConverted = `${className} ${stringify(bemProps)}`;

        if (/^https?:\/\//.test(to)) {
            return (
                <a
                  { ...props }
                  href={ to }
                    // eslint-disable-next-line react/forbid-dom-props
                  className={ classNameConverted }
                >
                    { children }
                </a>
            );
        }

        if (to?.pathname?.indexOf('servizio-dolce-casa-fa4y') > -1) {
            return (
                <a
                  { ...props }
                  href="dolce-casa"
                    // eslint-disable-next-line react/forbid-dom-props
                  className={ classNameConverted }
                >
                    { children }
                </a>
            );
        }

        return (
            <RouterLink
              { ...props }
              to={ to }
                // eslint-disable-next-line react/forbid-component-props
              className={ classNameConverted }
            >
                { children }
            </RouterLink>
        );
    }
}

export default LinkComponent;
