import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Highlight } from 'react-instantsearch-dom';

import './SearchResultPage.style.scss';

/** @namespace Pwa/Component/AlgoliaInstantSearch/SearchResultPage/Component/SearchResultPageComponent */
export class SearchResultPageComponent extends PureComponent {
    static propTypes = {
        hit: PropTypes.shape({
            objectID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            name: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired
        }).isRequired
    };

    render() {
        const { hit } = this.props;
        const { url: finalUrl } = hit;

        return (
            <a block="SearchResultPage" href={ finalUrl }>
                <Highlight attribute="name" hit={ hit } />
            </a>
        );
    }
}

export default SearchResultPageComponent;
