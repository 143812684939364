export const LOAD_MC_AVAILABILITY_ACTION_TYPE = 'MCAVAILABILITY_ACTION';
export const LOAD_MC_AVAILABILITY_ACTION_PENDING_TYPE = 'MCAVAILABILITY_ACTION_PENDING';

/** @namespace Pwa/Store/McAvailability/Action/loadMcAvailability */
export const loadMcAvailability = (mcAvailability) => ({
    type: LOAD_MC_AVAILABILITY_ACTION_TYPE,
    mcAvailability
});

/** @namespace Pwa/Store/McAvailability/Action/loadMcAvailabilityPending */
export const loadMcAvailabilityPending = () => ({
    type: LOAD_MC_AVAILABILITY_ACTION_PENDING_TYPE
});
