/** @namespace Pwa/Util/mcMobileCheck/startMobile */
export const startMobile = () => window.matchMedia('(max-width: 1200px)').matches;
/** @namespace Pwa/Util/mcMobileCheck/isMobile */
export const isMobile = () => window.matchMedia('(max-width: 767px)').matches;

/** @namespace Pwa/Util/mcMobileCheck/isMidMobile */
export const isMidMobile = () => window.matchMedia('(max-width: 1023px)').matches;

/** @namespace Pwa/Util/mcMobileCheck/isComputer */
export const isComputer = () => window.matchMedia('(min-width: 1400px)').matches;
