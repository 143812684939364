export const OPEN_CATEGORY_FILTERS = 'OPEN_CATEGORY_FILTERS';
export const CLOSE_CATEGORY_FILTERS = 'CLOSE_CATEGORY_FILTERS';
export const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER';
export const SET_CATEGORY_ATTRIBUTE_SET_FILTER = 'SET_CATEGORY_ATTRIBUTE_SET_FILTER';
export const CLEAR_CATEGORY_FILTER = 'CLEAR_CATEGORY_FILTER';

/** @namespace Pwa/Store/CategoryFilters/Action/openCategoryFilters */
export const openCategoryFilters = () => ({
    type: OPEN_CATEGORY_FILTERS
});

/** @namespace Pwa/Store/CategoryFilters/Action/closeCategoryFilters */
export const closeCategoryFilters = () => ({
    type: CLOSE_CATEGORY_FILTERS
});

/** @namespace Pwa/Store/CategoryFilters/Action/setCategoryFilter */
export const setCategoryFilter = (payload) => ({
    type: SET_CATEGORY_FILTER,
    payload
});

/** @namespace Pwa/Store/CategoryFilters/Action/setCategoryAttributeSetFilter */
export const setCategoryAttributeSetFilter = (payload) => ({
    type: SET_CATEGORY_ATTRIBUTE_SET_FILTER,
    payload
});

/** @namespace Pwa/Store/CategoryFilters/Action/clearCategoryFilter */
export const clearCategoryFilter = () => ({
    type: CLEAR_CATEGORY_FILTER
});
